import {CREATE_ABOUT, DELETE_ABOUTS, GET_ABOUT, GET_ABOUTS} from "../constants/aboutConstants";


const INITIAL_STATE = {
    abouts: []
}

const aboutReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_ABOUT:
            return {
                abouts: [...state.abouts, action.payload]
            }
        case GET_ABOUTS:
            return {
                abouts: [...action.payload]
            }
        case GET_ABOUT:
            return {
                about: action.payload
            }
        case DELETE_ABOUTS:
            return {
                abouts: state.abouts.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    }
}

export default aboutReducer