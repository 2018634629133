import React from "react";
import GalleryImages from "./GalleryImages";
import {useSelector} from "react-redux";

const Gallery = () => {
    const {galleries} = useSelector(state => state.galleries)
    return (
        <div className="gallery" id="gallery">
            <div className="container">
                <div className="about-heading">
                    <h3>Наша галерея</h3>
                </div>
            </div>
            <div className="gallery-grids">
                    <GalleryImages galleries={galleries}/>
                <div className="clearfix"/>
            </div>
        </div>
    )
}

export default Gallery