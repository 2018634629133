import axios from "axios"
import {START_LOADING, STOP_LOADING} from '../constants/loadingConstants'
import {SHOW_ERROR_MESSAGE, SHOW_SUCCESS_MESSAGE} from "../constants/messageConstants"
import {CREATE_ABOUT, DELETE_ABOUTS, GET_ABOUT, GET_ABOUTS} from "../constants/aboutConstants";

export const createAbout = formData => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.post('/api/about', formData)
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_SUCCESS_MESSAGE, payload: response.data.successMessage})
        dispatch({type: CREATE_ABOUT, payload: response.data.about})
        // console.log(formData)
    } catch (err) {
        console.log('createAbout api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const getAbouts = () => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.get('/api/about')
        // console.log(response);
        dispatch({type: STOP_LOADING})
        dispatch({type: GET_ABOUTS, payload: response.data.abouts})
    } catch (err) {
        console.log('getAbouts api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const getAbout = (aboutId) => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.get(`/api/about/${aboutId}`)
        dispatch({type: STOP_LOADING})
        dispatch({type: GET_ABOUT, payload: response.data})
    } catch (err) {
        console.log('getAbout api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const deleteAbouts = (aboutId) => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.delete(`/api/about/${aboutId}`)
        // console.log(response);
        dispatch({type: STOP_LOADING})
        dispatch({type: DELETE_ABOUTS, payload: response.data})
    } catch (err) {
        console.log('deleteAbouts api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}