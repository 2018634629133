import React from "react";

export const showErrorMsg = (msg) => {
    const alertDiv = () => {
        let div = document.querySelector('.alert')
        div.style.display='none'
    }
    return (
        <div className="alert fadeInDown">
            <span className="closebtn" onClick={alertDiv}>&times;</span>
            {msg}
        </div>
    )
}
export const showSuccessMsg = (msg) => {
    const alertDiv = () => {
        let div = document.querySelector('.success')
        div.style.display='none'
    }
    return (
        <div className="success fadeInDown">
            <span className="closebtn" onClick={alertDiv}>&times;</span>
            {msg}
        </div>
    )
}
