import React from 'react';
import {Link} from "react-router-dom";

const AdminContactCard = ({contact}) => {
    return (
        <div className="col">
            <div className="card h-100 w-50">
                <div className="card-body">
                    <h5 className="card-title">Адресс</h5>
                    <span className="card-text">{contact.address}</span>
                    <h5 className="card-title">Номер телефона</h5>
                    <span className="card-text">{contact.phone}</span>
                    <h5 className="card-title">Email</h5>
                    <span className="card-text">{contact.email}</span>
                    <h5 className="card-title">Дополнительная информация</h5>
                    <span className="card-text">{contact.info}</span>
                    <div className={'w-25 text-center mt-3'}>
                    <Link to={`/admin/edit/contact/${contact._id}`} type={'button'}
                          className={'btn btn-secondary btn-sm me-2 my-1'}><i
                        className={'fa fa-edit pr-1'}/> Редактировать
                    </Link>
                    </div>
                    {/*<button type={'button'} className={'btn btn-danger btn-sm'}*/}
                    {/*        onClick={() => dispatch(deleteHeaders(header._id))}><i*/}
                    {/*    className={'fa fa-trash pr-1'}/> Удалить*/}
                    {/*</button>*/}
                </div>
            </div>
        </div>
    );
};

export default AdminContactCard;