import React from "react";

export const showLoading = () => {
      return (
          <div className="loading">
              <div className="dot"/>
              <div className="dot"/>
              <div className="dot"/>
              <div className="dot"/>
              <div className="dot"/>
          </div>
    )
}