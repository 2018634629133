import React, {Fragment} from 'react';
import AdminHeaderModal from "./AdminHeaderModal";
import AdminAboutModal from "./AdminAboutModal";
import AdminGalleryModal from "./AdminGalleryModal";
import AdminServicesModal from "./AdminServicesModal";
import AdminContactModal from "./AdminContactModal";
import {useSelector} from "react-redux";

const AdminActionBtns = () => {
    const {headers} = useSelector(state => state.headers)
    const {abouts} = useSelector(state => state.abouts)
    const {galleries} = useSelector(state => state.galleries)
    const {contacts} = useSelector(state => state.contacts)
    const {services} = useSelector(state => state.services)

    return (
        <Fragment>
            <div className='bg-light my-2'>
                <div className="container">
                    <div className="row">
                        {headers && headers.length > 0 ? null : (
                            <div id={'btn-header'} className="col-md-4 py-3 my-1">
                                <button type='button' className='btn btn-outline-info btn-block w-100'
                                        data-bs-toggle="modal"
                                        data-bs-target='#addHeaderModal'>
                                    <i className='fa fa-edit'> Изменить блок Header</i>
                                </button>
                            </div>
                        )}
                        {abouts && abouts.length > 0 ? null : (
                            <div className="col-md-4 py-3 my-1">
                                <button type='button' className='btn btn-outline-info btn-block w-100'
                                        data-bs-toggle="modal"
                                        data-bs-target='#addAboutModal'>
                                    <i className='fa fa-edit'> Изменить блок About</i>
                                </button>
                            </div>
                        )}
                        {galleries && galleries.length > 22 ? null : (
                            <div className="col-md-4 py-3 my-1">
                                <button type='button' className='btn btn-outline-info btn-block w-100'
                                        data-bs-toggle="modal"
                                        data-bs-target='#addGalleryModal'>
                                    <i className='fa fa-edit'> Добавить картинку в Галлерею</i>
                                </button>
                            </div>
                        )}
                        {contacts && contacts.length > 0 ? null : (
                        <div className="col-md-4 py-3 my-1">
                            <button type='button' className='btn btn-outline-info btn-block w-100'
                                    data-bs-toggle="modal"
                                    data-bs-target='#addServicesModal'>
                                <i className='fa fa-edit'> Перечень услуг и стоимость</i>
                            </button>
                        </div>
                        )}
                        {services && services.length > 0 ? null : (
                        <div className="col-md-4 py-3 my-1">
                            <button type='button' className='btn btn-outline-info btn-block w-100'
                                    data-bs-toggle="modal"
                                    data-bs-target='#addContactModal'>
                                <i className='fa fa-edit'> Контакты</i>
                            </button>
                        </div>
                        )}
                    </div>
                </div>
            </div>
            <AdminHeaderModal/>
            <AdminAboutModal/>
            <AdminGalleryModal/>
            <AdminServicesModal/>
            <AdminContactModal/>
        </Fragment>
    )
};

export default AdminActionBtns;