import React from "react";

const AboutIconAndText = ({about}) => {
    return (
        <div className="col-md-6 w3ls-about-left">
            <div className={"agileits-icon-grid"}>
                <div className={"icon-right"}>
                    <h5>{about.aboutHeadOne}</h5>
                    <p>{about.aboutTextOne}</p>
                </div>
                <div className={"clearfix"}/>
            </div>
            <div className={"agileits-icon-grid"}>
                <div className={"icon-right"}>
                    <h5>{about.aboutHeadTwo}</h5>
                    <p>{about.aboutTextTwo}</p>
                </div>
                <div className={"clearfix"}/>
            </div>
            <div className={"agileits-icon-grid"}>
                <div className={"icon-right"}>
                    <h5>{about.aboutHeadThree}</h5>
                    <p>{about.aboutTextThree}</p>
                </div>
                <div className={"clearfix"}/>
            </div>
        </div>
    )
}

export default AboutIconAndText