import React, { Fragment, useState } from 'react';
import isEmpty from "validator/es/lib/isEmpty";
import { showErrorMsg, showSuccessMsg } from "../../helpers/massage";
import { showLoading } from "../../helpers/loading";
import { useSelector, useDispatch } from 'react-redux'
import { clearMessages } from '../../redux/actions/messageActions'
import { createAbout } from '../../redux/actions/aboutActions'

const AdminAboutModal = () => {
    const { loading } = useSelector(state => state.loading)
    const { successMsg, errorMsg } = useSelector(state => state.messages)

    const dispatch = useDispatch()

    const [clientSideError, setClientSideError] = useState('')
    const [aboutData, setAboutData] = useState({
        aboutImage: null,
        aboutTextOne: '',
        aboutTextTwo: '',
        aboutTextThree: '',
        aboutHeadOne: '',
        aboutHeadTwo: '',
        aboutHeadThree: '',
    })

    const {
        aboutImage,
        aboutTextOne,
        aboutTextTwo,
        aboutTextThree,
        aboutHeadOne,
        aboutHeadTwo,
        aboutHeadThree,
    } = aboutData

    const handleMessages = evt => {
        dispatch(clearMessages())
        setClientSideError('')
    }

    const handleAboutImage = evt => {
        setAboutData({
            ...aboutData,
            [evt.target.name]: evt.target.files[0]
        })
    }

    const handleAboutChange = evt => {
        setAboutData({
            ...aboutData,
            [evt.target.name]: evt.target.value,
        })
    }

    const handleAboutSubmit = evt => {
        evt.preventDefault()

        if (aboutImage === null) {
            setAboutData({
                ...aboutData
            })
            setClientSideError("Пожалуйста добавте изображение")
        } else if (isEmpty(aboutTextOne) || isEmpty(aboutTextTwo) || isEmpty(aboutTextThree) || isEmpty(aboutHeadOne) || isEmpty(aboutHeadTwo) || isEmpty(aboutHeadThree)) {
            setAboutData({
                ...aboutData
            })
            setClientSideError("Все поля обязательные")
        } else {
            let formData = new FormData()
            formData.append('aboutImage', aboutImage)
            formData.append('aboutTextOne', aboutTextOne)
            formData.append('aboutTextTwo', aboutTextTwo)
            formData.append('aboutTextThree', aboutTextThree)
            formData.append('aboutHeadOne', aboutHeadOne)
            formData.append('aboutHeadTwo', aboutHeadTwo)
            formData.append('aboutHeadThree', aboutHeadThree)

            dispatch(createAbout(formData))
            setAboutData({
                ...aboutData,
                aboutImage: null,
                aboutTextOne: '',
                aboutTextTwo: '',
                aboutTextThree: '',
                aboutHeadOne: '',
                aboutHeadTwo: '',
                aboutHeadThree: '',
            })
        }
    }



    return (
        <div id='addAboutModal' className='modal' onClick={handleMessages}>
            <div className='modal-dialog modal-dialog-centered modal-lg'>
                <div className='modal-content'>
                    <form onSubmit={handleAboutSubmit}>
                        <div className='modal-header bg-info text-white'>
                            <h5 className='modal-title'>Изменить блок About</h5>
                            <button type='button' className='close bg-transparent btn-outline-dark border-0'
                                    data-bs-dismiss={'modal'}>
                                <span><i className={'fa fa-times'} /></span>
                            </button>
                        </div>
                        <div className='modal-body my-2'>
                            {clientSideError && showErrorMsg(clientSideError)}
                            {errorMsg && showErrorMsg(errorMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                            {
                                loading ? (
                                    showLoading()
                                ) : (
                                    <Fragment>
                                        <div className="mb-3">
                                            <label htmlFor="formFile" className="form-label">Фоновое изображение</label>
                                            <input className="form-control" name='aboutImage'
                                                   type="file" id="formFile" onChange={handleAboutImage} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label htmlFor='aboutHeadOne' className={'text-secondary'}>Заголовок 1</label>
                                            <input id="aboutHeadOne" name="aboutHeadOne" value={aboutHeadOne} type="text"
                                                   className={'form-control my-2'}
                                                   onChange={handleAboutChange} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label htmlFor='aboutTextOne' className={'text-secondary'}>Текст 1</label>
                                            <input id="aboutTextOne" name="aboutTextOne" value={aboutTextOne} type="text"
                                                   className={'form-control my-2'}
                                                   onChange={handleAboutChange} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label htmlFor='aboutHeadTwo' className={'text-secondary'}>Заголовок 2</label>
                                            <input id="aboutHeadTwo" name="aboutHeadTwo" value={aboutHeadTwo} type="text"
                                                   className={'form-control my-2'}
                                                   onChange={handleAboutChange} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label htmlFor='aboutTextTwo' className={'text-secondary'}>Текст 2</label>
                                            <input id="aboutTextTwo" name="aboutTextTwo" value={aboutTextTwo} type="text"
                                                   className={'form-control my-2'}
                                                   onChange={handleAboutChange} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label htmlFor='aboutHeadThree' className={'text-secondary'}>Заголовок 3</label>
                                            <input id="aboutHeadThree" name="aboutHeadThree" value={aboutHeadThree} type="text"
                                                   className={'form-control my-2'}
                                                   onChange={handleAboutChange} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label htmlFor='aboutTextThree' className={'text-secondary'}>Текст 3</label>
                                            <input id="aboutTextThree" name="aboutTextThree" value={aboutTextThree} type="text"
                                                   className={'form-control my-2'}
                                                   onChange={handleAboutChange} />
                                        </div>
                                    </Fragment>
                                )
                            }
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className={'btn btn-danger'} data-bs-dismiss={'modal'}>Закрыть
                            </button>
                            <button type='submit' className={'btn btn-success'}>Сохранить</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminAboutModal;