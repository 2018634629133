import React from "react";

const Copyrigth = () => {
    const localeDate = () => {
        return new Date().getFullYear()
    }
    return (
        <div className='copyright'>
            <div className='container'>
                <p>© {localeDate()} Бассейн ПНИПУ. All rights reserved | Design by <a
                    href='http://w3layouts.com'>W3layouts</a> | <a href='https://pstu.ru'>ПНИПУ</a></p>
            </div>
        </div>
    )
}

export default Copyrigth