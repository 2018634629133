import React from 'react';
// import {useDispatch} from "react-redux";
// import {deleteHeaders} from "../../redux/actions/headerActions";
import {Link} from "react-router-dom";

const AdminHeaderCard = ({header}) => {
    // const dispatch = useDispatch()

    return (
        <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col">
                <div className="card h-100">
                        <img src={`/assets/images/${header.fileName}`} className="card-img-top" alt="header"/>
                    <div className="card-body text-center">
                        <h5 className="card-title">{header.headerTitle}</h5>
                        <Link to={`/admin/edit/header/${header._id}`} type={'button'}
                              className={'btn btn-secondary btn-sm me-2 my-1'}><i
                            className={'fa fa-edit pr-1'}/> Редактировать
                        </Link>
                        {/*<button type={'button'} className={'btn btn-danger btn-sm'}*/}
                        {/*        onClick={() => dispatch(deleteHeaders(header._id))}><i*/}
                        {/*    className={'fa fa-trash pr-1'}/> Удалить*/}
                        {/*</button>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminHeaderCard;