import React, {useEffect, useState, Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getGallery} from "../../redux/actions/galleryActions";
import AdminHeader from "./AdminHeader";
import {Link} from "react-router-dom";
import {updateGallery} from "../../api/gallery";

const AdminEditGallery = ({match, history}) => {
    const galleryId = match.params.galleryId

    const dispatch = useDispatch()
    const {gallery} = useSelector(state => state.galleries)

    const [galleryImage, setGalleryImage] = useState(null)


    useEffect(() => {
        if (!gallery) {
            dispatch(getGallery(galleryId))
        } else {
            setGalleryImage(gallery.gallery.fileName)
        }
    }, [dispatch, galleryId, gallery])

    const handleImageUpload = evt => {
        const image = evt.target.files[0]
        setGalleryImage(image)
    }

    const handleGallerySubmit = async evt => {
        evt.preventDefault()

        const formData = new FormData()
        formData.append('galleryImage', galleryImage)

        updateGallery(formData, galleryId)
            .then(res => {
                history.push('/admin/dashboard')
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <Fragment>
            <AdminHeader />
            <div className='container my-3'>
                <div className='row'>
                    <div className='col-md-8 mx-auto'>
                        <Link to='/admin/dashboard'>
                            <span className='fa fa-arrow-left'>Назад</span>
                        </Link>
                        <div>
                            <br/>
                            <div className='modal-content'>
                                <form onSubmit={handleGallerySubmit}>
                                    <div className='modal-header bg-info text-white'>
                                        <h5 className='modal-title'>
                                            Изменить название и главную картинку
                                        </h5>
                                    </div>
                                    <div className='modal-body my-2'>
                                        <Fragment>
                                            <label className='btn btn-dark me-4'>
                                                Загрузить картинку
                                                <input
                                                    type='file'
                                                    name='galleryImage'
                                                    accept='images/*'
                                                    hidden
                                                    onChange={handleImageUpload}
                                                />
                                            </label>
                                            {galleryImage && galleryImage.name ? (
                                                <span className='badge bg-secondary'>
													{galleryImage.name}
												</span>
                                            ) : galleryImage ? (
                                                <img
                                                    className='img-thumbnail'
                                                    style={{
                                                        width: '120px',
                                                        height: '80px',
                                                    }}
                                                    src={`/assets/images/${galleryImage}`}
                                                    alt='product'
                                                />
                                            ) : null}
                                        </Fragment>
                                    </div>
                                    <div className='modal-footer'>
                                        <button type='submit' className='btn btn-success text-white'>
                                            Сохранить
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AdminEditGallery;