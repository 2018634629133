import { combineReducers, applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import loadingReducer from './reducers/loadingReducers'
import messageReducer from './reducers/messageReducers'
import headerReducer from './reducers/headerReducers'
import aboutReducer from "./reducers/aboutReducers";
import galleryReducer from "./reducers/galleryReducers";
import serviceReducer from "./reducers/serviceReducers";
import contactReducer from "./reducers/contactReducers";

const reducer = combineReducers({
    loading: loadingReducer,
    messages: messageReducer,
    headers: headerReducer,
    abouts: aboutReducer,
    galleries: galleryReducer,
    services: serviceReducer,
    contacts: contactReducer
})

const initialState = {}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store