import axios from "axios"
import {START_LOADING, STOP_LOADING} from '../constants/loadingConstants'
import {SHOW_ERROR_MESSAGE, SHOW_SUCCESS_MESSAGE} from "../constants/messageConstants"
import {CREATE_SERVICE, GET_SERVICE, GET_SERVICES, DELETE_SERVICES} from "../constants/serviceConstants";


export const createService = formData => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        dispatch({type: START_LOADING})
        const response = await axios.post('/api/service', formData, config)
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_SUCCESS_MESSAGE, payload: response.data.successMessage})
        dispatch({type: CREATE_SERVICE, payload: response.data.service})
    } catch (err) {
        console.log('createService api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const getServices = () => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.get('/api/service')
        // console.log(response);
        dispatch({type: STOP_LOADING})
        dispatch({type: GET_SERVICES, payload: response.data.services})
    } catch (err) {
        console.log('getServices api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const getService = (serviceId) => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.get(`/api/service/${serviceId}`)
        dispatch({type: STOP_LOADING})
        dispatch({type: GET_SERVICE, payload: response.data})
    } catch (err) {
        console.log('getService api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const deleteServices = (serviceId) => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.delete(`/api/service/${serviceId}`)
        // console.log(response);
        dispatch({type: STOP_LOADING})
        dispatch({type: DELETE_SERVICES, payload: response.data})
    } catch (err) {
        console.log('deleteServices api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}