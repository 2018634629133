import React, {useState, Fragment} from 'react';
import {showErrorMsg, showSuccessMsg} from "../../helpers/massage";
import {showLoading} from "../../helpers/loading";
import {useDispatch, useSelector} from "react-redux";
import {clearMessages} from "../../redux/actions/messageActions";
import {createGallery} from "../../redux/actions/galleryActions";

const AdminGalleryModal = () => {
    const {loading} = useSelector(state => state.loading)
    const {successMsg, errorMsg} = useSelector(state => state.messages)

    const dispatch = useDispatch()

    const [clientSideError, setClientSideError] = useState('')
    const [galleryData, setGalleryData] = useState({
        galleryImage: null,
    })

    const {
        galleryImage,
    } = galleryData

    const handleMessages = evt => {
        dispatch(clearMessages())
        setClientSideError('')
    }

    const handleGalleryImage = evt => {
        setGalleryData({
            ...galleryData,
            [evt.target.name]: evt.target.files[0]
        })
    }

    const handleGallerySubmit = evt => {
        evt.preventDefault()

        if (galleryImage === null) {
            setGalleryData({
                ...galleryData
            })
            setClientSideError("Пожалуйста добавьте изображение")
        } else {
            let formData = new FormData()
            formData.append('galleryImage', galleryImage)

            dispatch(createGallery(formData))
            setGalleryData({
                ...galleryData,
                aboutImage: null,
            })
        }
    }

    return (
        <div id='addGalleryModal' className='modal' onClick={handleMessages}>
            <div className='modal-dialog modal-dialog-centered modal-lg'>
                <div className='modal-content'>
                    <form onSubmit={handleGallerySubmit}>
                        <div className='modal-header bg-info text-white'>
                            <h5 className='modal-title'>Изменить блок Gallery</h5>
                            <button className='close bg-transparent btn-outline-dark border-0'
                                    data-bs-dismiss={'modal'}>
                                <span><i className={'fa fa-times'}/></span>
                            </button>
                        </div>
                        <div className='modal-body my-2'>
                            {clientSideError && showErrorMsg(clientSideError)}
                            {errorMsg && showErrorMsg(errorMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                            {
                                loading ? (
                                    showLoading()
                                ) : (
                                    <Fragment>
                                        <div className="mb-3">
                                            <label htmlFor="formFile" className="form-label">Загрузить изображение в
                                                галерею</label>
                                            <input className="form-control" name='galleryImage'
                                                   type="file" id="formFile" onChange={handleGalleryImage}/>
                                        </div>
                                    </Fragment>
                                )}
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className={'btn btn-danger'} data-bs-dismiss={'modal'}>Закрыть
                            </button>
                            <button type='submit' className={'btn btn-success'}>Сохранить</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminGalleryModal;