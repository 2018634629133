import React from "react";

const HeaderCategories = () => {
        return (
            <ul className="nav nav-pills">
                    <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href={"#banner"}>Главная</a>
                    </li>
                    <li className="nav-item">
                            <a className="nav-link" href={"#about"}>О нас</a>
                    </li>
                    <li className="nav-item">
                            <a className="nav-link" href={"#gallery"}>Галерея</a>
                    </li>
                     <li className="nav-item">
                             <a className="nav-link" href={"#services"}>Перечень услуг</a>
                    </li>
                     <li className="nav-item">
                             <a className="nav-link" href={"#contact"}>Контакты</a>
                    </li>

            </ul>
        )
}

export default HeaderCategories