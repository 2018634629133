import {CREATE_CONTACT, GET_CONTACT, GET_CONTACTS, DELETE_CONTACTS} from "../constants/contactConstants";


const INITIAL_STATE = {
    contacts: []
}

const contactReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_CONTACT:
            return {
                contacts: [...state.contacts, action.payload]
            }
        case GET_CONTACTS:
            return {
                contacts: [...action.payload]
            }
        case GET_CONTACT:
            return {
                contact: action.payload
            }
        case DELETE_CONTACTS:
            return {
                contacts: state.contacts.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    }
}

export default contactReducer