import {CREATE_GALLERY, DELETE_GALLERIES, GET_GALLERY, GET_GALLERIES} from "../constants/galleryConstants";


const INITIAL_STATE = {
    galleries: []
}

const galleryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_GALLERY:
            return {
                galleries: [...state.galleries, action.payload]
            }
        case GET_GALLERIES:
            return {
                galleries: [...action.payload]
            }
        case GET_GALLERY:
            return {
                gallery: action.payload
            }
        case DELETE_GALLERIES:
            return {
                galleries: state.galleries.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    }
}

export default galleryReducer