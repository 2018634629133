import axios from "axios";

export const createGallery = async (data) => {

    return await axios.post('/api/gallery', data)
}
export const updateGallery = async (data, galleryId) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    return await axios.put(`/api/gallery/${galleryId}`, data, config)
}

