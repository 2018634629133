import React, {Fragment} from "react";
import HeaderLogo from "./HeaderLogo";
// import HeaderIco from "./HeaderIco";
import HeaderCategories from "./HeaderCategories";
import {useSelector} from "react-redux";


const Header = () => {
    const {headers} = useSelector(state => state.headers)

    return (
        <Fragment>
            {headers && headers.map((header) => (
                <div key={header._id + Math.random()} className="banner" id='banner' style={{backgroundImage: `url("/assets/images/${header.fileName}")`}}>
                    <div className="header">
                        <div className="container">
                            <div className="header-container">
                                <div className="header-left">

                                    <HeaderLogo key={header._id + Math.random()} header={header}/>

                                    {/*<div className="agileinfo-social-grids">*/}
                                    {/*    <HeaderIco/>*/}
                                    {/*</div>*/}
                                    <div className="clearfix"></div>
                                </div>
                                <div className="top-nav">
                                    <HeaderCategories/>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <div className="clearfix"></div>

                        </div>
                    </div>
                    {/* <div className="banner-info">
                <div className="container">
                    <h3>Новое поколение в обучении</h3>
                    <h2>уроки плавания</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut posuere tempor quam, vitae
                        vestibulum
                        lacus
                        placerat non. Donec volutpat odio sed neque molestie elementum quis nec nisi. Phasellus elit
                        leo, lobortis non
                        massa in, elementum maximus urna.</p>
                </div>
            </div> */}
                </div>
            ))}
        </Fragment>
    )
}
export default Header