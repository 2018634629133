import axios from "axios";

export const createAbout = async (data) => {

    return await axios.post('/api/about', data)
}
export const updateAbout = async (data, aboutId) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    return await axios.put(`/api/about/${aboutId}`, data, config)
}

