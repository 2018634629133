import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import AdminHeader from "./AdminHeader";
import {Link} from "react-router-dom";
import {updateService} from "../../api/service";
import {getService} from "../../redux/actions/serviceActions";

const AdminEditServices = ({match, history}) => {
    const serviceId = match.params.serviceId

    const dispatch = useDispatch()
    const {service} = useSelector(state => state.services)
    const [serviceData, setServiceData] = useState({
        servicesTableHead: '',
        freeSwimmingOneTimeVisitWeekdayAdult: '',
        freeSwimmingOneTimeVisitWeekendsAdult: '',
        freeSwimmingOneTimeVisitWeekdayChild: '',
        freeSwimmingOneTimeVisitWeekendsChild: '',
        freeSwimmingFourVisitsPerMonthWeekdayAdult: '',
        freeSwimmingFourVisitsPerMonthWeekendsAdult: '',
        freeSwimmingFourVisitsPerMonthWeekdayChild: '',
        freeSwimmingFourVisitsPerMonthWeekendsChild: '',
        freeSwimmingEightVisitsPerMonthWeekdayAdult: '',
        freeSwimmingEightVisitsPerMonthWeekendsAdult: '',
        freeSwimmingEightVisitsPerMonthWeekdayChild: '',
        freeSwimmingEightVisitsPerMonthWeekendsChild: '',
        freeSwimmingTwelveVisitsPerMonthWeekdayAdult: '',
        freeSwimmingTwelveVisitsPerMonthWeekendsAdult: '',
        freeSwimmingTwelveVisitsPerMonthWeekdayChild: '',
        freeSwimmingTwelveVisitsPerMonthWeekendsChild: '',
        preferentialVisitsOneTimeVisitStudents: '',
        preferentialVisitsOneTimeVisitStaff: '',
        preferentialVisitsFourVisitsPerMonthWeekdayStudents: '',
        preferentialVisitsFourVisitsPerMonthWeekendsStaff: '',
        preferentialVisitsEightVisitsPerMonthWeekdayStudents: '',
        preferentialVisitsEightVisitsPerMonthWeekendsStaff: '',
        preferentialVisitsTwelveVisitsPerMonthWeekdayStudents: '',
        preferentialVisitsTwelveVisitsPerMonthWeekendsStaff: '',
        pensionersOneTimeVisitStudents: '',
        pensionersOneTimeVisitStaff: '',
        pensionersFourVisitsPerMonthWeekdayStudents: '',
        pensionersFourVisitsPerMonthWeekendsStaff: '',
        pensionersEightVisitsPerMonthWeekdayStudents: '',
        pensionersEightVisitsPerMonthWeekendsStaff: '',
        pensionersTwelveVisitsPerMonthWeekdayStudents: '',
        pensionersVisitsTwelveVisitsPerMonthWeekendsStaff: '',
        otherServicesSixTracksStudents: '',
        otherServicesSixTracksStaff: '',
        otherServicesOneTrackStudents: '',
        otherServicesOneTrackStaff: '',
        servicesTableText: ''
    })

    const {
        servicesTableHead,
        freeSwimmingOneTimeVisitWeekdayAdult,
        freeSwimmingOneTimeVisitWeekendsAdult,
        freeSwimmingOneTimeVisitWeekdayChild,
        freeSwimmingOneTimeVisitWeekendsChild,
        freeSwimmingFourVisitsPerMonthWeekdayAdult,
        freeSwimmingFourVisitsPerMonthWeekendsAdult,
        freeSwimmingFourVisitsPerMonthWeekdayChild,
        freeSwimmingFourVisitsPerMonthWeekendsChild,
        freeSwimmingEightVisitsPerMonthWeekdayAdult,
        freeSwimmingEightVisitsPerMonthWeekendsAdult,
        freeSwimmingEightVisitsPerMonthWeekdayChild,
        freeSwimmingEightVisitsPerMonthWeekendsChild,
        freeSwimmingTwelveVisitsPerMonthWeekdayAdult,
        freeSwimmingTwelveVisitsPerMonthWeekendsAdult,
        freeSwimmingTwelveVisitsPerMonthWeekdayChild,
        freeSwimmingTwelveVisitsPerMonthWeekendsChild,
        preferentialVisitsOneTimeVisitStudents,
        preferentialVisitsOneTimeVisitStaff,
        preferentialVisitsFourVisitsPerMonthWeekdayStudents,
        preferentialVisitsFourVisitsPerMonthWeekendsStaff,
        preferentialVisitsEightVisitsPerMonthWeekdayStudents,
        preferentialVisitsEightVisitsPerMonthWeekendsStaff,
        preferentialVisitsTwelveVisitsPerMonthWeekdayStudents,
        preferentialVisitsTwelveVisitsPerMonthWeekendsStaff,
        pensionersOneTimeVisitStudents,
        pensionersOneTimeVisitStaff,
        pensionersFourVisitsPerMonthWeekdayStudents,
        pensionersFourVisitsPerMonthWeekendsStaff,
        pensionersEightVisitsPerMonthWeekdayStudents,
        pensionersEightVisitsPerMonthWeekendsStaff,
        pensionersTwelveVisitsPerMonthWeekdayStudents,
        pensionersVisitsTwelveVisitsPerMonthWeekendsStaff,
        otherServicesSixTracksStudents,
        otherServicesSixTracksStaff,
        otherServicesOneTrackStudents,
        otherServicesOneTrackStaff,
        servicesTableText
    } = serviceData

    useEffect(() => {
        if (!service) {
            dispatch(getService(serviceId))
        } else {
            setServiceData({
                ...serviceData,
                servicesTableHead: service.service.servicesTableHead,
                freeSwimmingOneTimeVisitWeekdayAdult: service.service.freeSwimmingOneTimeVisitWeekdayAdult,
                freeSwimmingOneTimeVisitWeekendsAdult: service.service.freeSwimmingOneTimeVisitWeekendsAdult,
                freeSwimmingOneTimeVisitWeekdayChild: service.service.freeSwimmingOneTimeVisitWeekdayChild,
                freeSwimmingOneTimeVisitWeekendsChild: service.service.freeSwimmingOneTimeVisitWeekendsChild,
                freeSwimmingFourVisitsPerMonthWeekdayAdult: service.service.freeSwimmingFourVisitsPerMonthWeekdayAdult,
                freeSwimmingFourVisitsPerMonthWeekendsAdult: service.service.freeSwimmingFourVisitsPerMonthWeekendsAdult,
                freeSwimmingFourVisitsPerMonthWeekdayChild: service.service.freeSwimmingFourVisitsPerMonthWeekdayChild,
                freeSwimmingFourVisitsPerMonthWeekendsChild: service.service.freeSwimmingFourVisitsPerMonthWeekendsChild,
                freeSwimmingEightVisitsPerMonthWeekdayAdult: service.service.freeSwimmingEightVisitsPerMonthWeekdayAdult,
                freeSwimmingEightVisitsPerMonthWeekendsAdult: service.service.freeSwimmingEightVisitsPerMonthWeekendsAdult,
                freeSwimmingEightVisitsPerMonthWeekdayChild: service.service.freeSwimmingEightVisitsPerMonthWeekdayChild,
                freeSwimmingEightVisitsPerMonthWeekendsChild: service.service.freeSwimmingEightVisitsPerMonthWeekendsChild,
                freeSwimmingTwelveVisitsPerMonthWeekdayAdult: service.service.freeSwimmingTwelveVisitsPerMonthWeekdayAdult,
                freeSwimmingTwelveVisitsPerMonthWeekendsAdult: service.service.freeSwimmingTwelveVisitsPerMonthWeekendsAdult,
                freeSwimmingTwelveVisitsPerMonthWeekdayChild: service.service.freeSwimmingTwelveVisitsPerMonthWeekdayChild,
                freeSwimmingTwelveVisitsPerMonthWeekendsChild: service.service.freeSwimmingTwelveVisitsPerMonthWeekendsChild,
                preferentialVisitsOneTimeVisitStudents: service.service.preferentialVisitsOneTimeVisitStudents,
                preferentialVisitsOneTimeVisitStaff: service.service.preferentialVisitsOneTimeVisitStaff,
                preferentialVisitsFourVisitsPerMonthWeekdayStudents: service.service.preferentialVisitsFourVisitsPerMonthWeekdayStudents,
                preferentialVisitsFourVisitsPerMonthWeekendsStaff: service.service.preferentialVisitsFourVisitsPerMonthWeekendsStaff,
                preferentialVisitsEightVisitsPerMonthWeekdayStudents: service.service.preferentialVisitsEightVisitsPerMonthWeekdayStudents,
                preferentialVisitsEightVisitsPerMonthWeekendsStaff: service.service.preferentialVisitsEightVisitsPerMonthWeekendsStaff,
                preferentialVisitsTwelveVisitsPerMonthWeekdayStudents: service.service.preferentialVisitsTwelveVisitsPerMonthWeekdayStudents,
                preferentialVisitsTwelveVisitsPerMonthWeekendsStaff: service.service.preferentialVisitsTwelveVisitsPerMonthWeekendsStaff,
                pensionersOneTimeVisitStudents: service.service.pensionersOneTimeVisitStudents,
                pensionersOneTimeVisitStaff: service.service.pensionersOneTimeVisitStaff,
                pensionersFourVisitsPerMonthWeekdayStudents: service.service.pensionersFourVisitsPerMonthWeekdayStudents,
                pensionersFourVisitsPerMonthWeekendsStaff: service.service.pensionersFourVisitsPerMonthWeekendsStaff,
                pensionersEightVisitsPerMonthWeekdayStudents: service.service.pensionersEightVisitsPerMonthWeekdayStudents,
                pensionersEightVisitsPerMonthWeekendsStaff: service.service.pensionersEightVisitsPerMonthWeekendsStaff,
                pensionersTwelveVisitsPerMonthWeekdayStudents: service.service.pensionersTwelveVisitsPerMonthWeekdayStudents,
                pensionersVisitsTwelveVisitsPerMonthWeekendsStaff: service.service.pensionersVisitsTwelveVisitsPerMonthWeekendsStaff,
                otherServicesSixTracksStudents: service.service.otherServicesSixTracksStudents,
                otherServicesSixTracksStaff: service.service.otherServicesSixTracksStaff,
                otherServicesOneTrackStudents: service.service.otherServicesOneTrackStudents,
                otherServicesOneTrackStaff: service.service.otherServicesOneTrackStaff,
                servicesTableText: service.service.servicesTableText
            })
        }
    }, [dispatch, serviceId, service])

    const handleServiceChange = evt => {
        setServiceData({
            ...serviceData,
            [evt.target.name]: evt.target.value,
        })
    }

    const handleServiceSubmit = async evt => {
        evt.preventDefault()

        const data = {
            servicesTableHead,
            freeSwimmingOneTimeVisitWeekdayAdult,
            freeSwimmingOneTimeVisitWeekendsAdult,
            freeSwimmingOneTimeVisitWeekdayChild,
            freeSwimmingOneTimeVisitWeekendsChild,
            freeSwimmingFourVisitsPerMonthWeekdayAdult,
            freeSwimmingFourVisitsPerMonthWeekendsAdult,
            freeSwimmingFourVisitsPerMonthWeekdayChild,
            freeSwimmingFourVisitsPerMonthWeekendsChild,
            freeSwimmingEightVisitsPerMonthWeekdayAdult,
            freeSwimmingEightVisitsPerMonthWeekendsAdult,
            freeSwimmingEightVisitsPerMonthWeekdayChild,
            freeSwimmingEightVisitsPerMonthWeekendsChild,
            freeSwimmingTwelveVisitsPerMonthWeekdayAdult,
            freeSwimmingTwelveVisitsPerMonthWeekendsAdult,
            freeSwimmingTwelveVisitsPerMonthWeekdayChild,
            freeSwimmingTwelveVisitsPerMonthWeekendsChild,
            preferentialVisitsOneTimeVisitStudents,
            preferentialVisitsOneTimeVisitStaff,
            preferentialVisitsFourVisitsPerMonthWeekdayStudents,
            preferentialVisitsFourVisitsPerMonthWeekendsStaff,
            preferentialVisitsEightVisitsPerMonthWeekdayStudents,
            preferentialVisitsEightVisitsPerMonthWeekendsStaff,
            preferentialVisitsTwelveVisitsPerMonthWeekdayStudents,
            preferentialVisitsTwelveVisitsPerMonthWeekendsStaff,
            pensionersOneTimeVisitStudents,
            pensionersOneTimeVisitStaff,
            pensionersFourVisitsPerMonthWeekdayStudents,
            pensionersFourVisitsPerMonthWeekendsStaff,
            pensionersEightVisitsPerMonthWeekdayStudents,
            pensionersEightVisitsPerMonthWeekendsStaff,
            pensionersTwelveVisitsPerMonthWeekdayStudents,
            pensionersVisitsTwelveVisitsPerMonthWeekendsStaff,
            otherServicesSixTracksStudents,
            otherServicesSixTracksStaff,
            otherServicesOneTrackStudents,
            otherServicesOneTrackStaff,
            servicesTableText
        }
        updateService(data, serviceId)
            .then(res => {
                history.push('/admin/dashboard')
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <Fragment>
            <AdminHeader/>
            <div className='container p-0 my-3'>
                <div className='row'>
                        <Link to='/admin/dashboard'>
                            <span className='fa fa-arrow-left'>Назад</span>
                        </Link>
                            <br/>
                            <div className='modal-content p-0'>
                                <form onSubmit={handleServiceSubmit}>
                                    <div className='modal-header bg-info text-white'>
                                        <h5 className='modal-title'>
                                            РЕДАКТИРОВАТЬ БЛОК ПЕРЕЧЕНЬ УСЛУГ
                                        </h5>
                                    </div>
                                    <div className='modal-body my-2'>
                                        <div className={'services-table'}>
                                            <input name="servicesTableHead" value={servicesTableHead} type="text"
                                                   className={'services-table__head'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'services-list'}>Перечень услуг</div>
                                            <div className={'services-price'}>Стоимость услуг (руб.)</div>
                                            <div className={'services-adult'}>Для взрослых</div>
                                            <div className={'services-child'}>Для детей (до 14лет в сопровождении взрослых)</div>
                                            <div className={'services-weekday__adult'}>Будни</div>
                                            <div className={'services-weekends__adult'}>Выходные и праздничные дни</div>
                                            <div className={'services-weekday__child'}>Будни</div>
                                            <div className={'services-weekends__child'}>Выходные и праздничные дни</div>
                                            <div className={'free-swimming'}>Свободное плавание (45 мин.)</div>
                                            <div className={'free-swimming__one-time__visit'}>Разовое посещение</div>
                                            <input name="freeSwimmingOneTimeVisitWeekdayAdult" value={freeSwimmingOneTimeVisitWeekdayAdult} type="text"
                                                   className={'free-swimming__one-time__visit-weekday__adult'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingOneTimeVisitWeekendsAdult" value={freeSwimmingOneTimeVisitWeekendsAdult} type="text"
                                                   className={'free-swimming__one-time__visit-weekends__adult'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingOneTimeVisitWeekdayChild" value={freeSwimmingOneTimeVisitWeekdayChild} type="text"
                                                   className={'free-swimming__one-time__visit-weekday__child'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingOneTimeVisitWeekendsChild" value={freeSwimmingOneTimeVisitWeekendsChild} type="text"
                                                   className={'free-swimming__one-time__visit-weekends__child'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'free-swimming__four-visits__per-month'}>Абонемент на 4 посещения в
                                                месяц
                                            </div>
                                            <input name="freeSwimmingFourVisitsPerMonthWeekdayAdult" value={freeSwimmingFourVisitsPerMonthWeekdayAdult} type="text"
                                                   className={'free-swimming__four-visits__per-month-weekday__adult'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingFourVisitsPerMonthWeekendsAdult" value={freeSwimmingFourVisitsPerMonthWeekendsAdult} type="text"
                                                   className={'free-swimming__four-visits__per-month-weekends__adult'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingFourVisitsPerMonthWeekdayChild" value={freeSwimmingFourVisitsPerMonthWeekdayChild} type="text"
                                                   className={'free-swimming__four-visits__per-month-weekday__child'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingFourVisitsPerMonthWeekendsChild" value={freeSwimmingFourVisitsPerMonthWeekendsChild} type="text"
                                                   className={'free-swimming__four-visits__per-month-weekends__child'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'free-swimming__eight-visits__per-month'}>Абонемент на 8 посещения в
                                                месяц
                                            </div>
                                            <input name="freeSwimmingEightVisitsPerMonthWeekdayAdult" value={freeSwimmingEightVisitsPerMonthWeekdayAdult} type="text"
                                                   className={'free-swimming__eight-visits__per-month-weekday__adult'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingEightVisitsPerMonthWeekendsAdult" value={freeSwimmingEightVisitsPerMonthWeekendsAdult} type="text"
                                                   className={'free-swimming__eight-visits__per-month-weekends__adult'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingEightVisitsPerMonthWeekdayChild" value={freeSwimmingEightVisitsPerMonthWeekdayChild} type="text"
                                                   className={'free-swimming__eight-visits__per-month-weekday__child'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingEightVisitsPerMonthWeekendsChild" value={freeSwimmingEightVisitsPerMonthWeekendsChild} type="text"
                                                   className={'free-swimming__eight-visits__per-month-weekends__child'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'free-swimming__twelve-visits__per-month'}>Абонемент на 12 посещений в
                                                месяц
                                            </div>
                                            <input name="freeSwimmingTwelveVisitsPerMonthWeekdayAdult" value={freeSwimmingTwelveVisitsPerMonthWeekdayAdult} type="text"
                                                   className={'free-swimming__twelve-visits__per-month-weekday__adult'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingTwelveVisitsPerMonthWeekendsAdult" value={freeSwimmingTwelveVisitsPerMonthWeekendsAdult} type="text"
                                                   className={'free-swimming__twelve-visits__per-month-weekends__adult'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingTwelveVisitsPerMonthWeekdayChild" value={freeSwimmingTwelveVisitsPerMonthWeekdayChild} type="text"
                                                   className={'free-swimming__twelve-visits__per-month-weekday__child'}
                                                   onChange={handleServiceChange}/>
                                            <input name="freeSwimmingTwelveVisitsPerMonthWeekendsChild" value={freeSwimmingTwelveVisitsPerMonthWeekendsChild} type="text"
                                                   className={'free-swimming__twelve-visits__per-month-weekends__child'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'preferential-visits'}>Льготные посещения для студентов ПНИПУ очной
                                                формы обучения, аспирантов, сотрудников ПНИПУ и бывших работников ПНИПУ, имеющих
                                                звание "Ветеран ПНИПУ" (45 мин.)
                                            </div>
                                            <div className={'preferential-visits__students'}>Студенты, Аспиранты</div>
                                            <div className={'preferential-visits__staff'}>Сотрудники, "Ветераны ПНИПУ"</div>
                                            <div className={'preferential-visits__one-time__visit'}>Разовое посещение</div>
                                            <input name="preferentialVisitsOneTimeVisitStudents" value={preferentialVisitsOneTimeVisitStudents} type="text"
                                                   className={'preferential-visits__one-time__visit__students'}
                                                   onChange={handleServiceChange}/>
                                            <input name="preferentialVisitsOneTimeVisitStaff" value={preferentialVisitsOneTimeVisitStaff} type="text"
                                                   className={'preferential-visits__one-time__visit__staff'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'preferential-visits__four-visits__per-month'}>Абонемент на 4 посещения
                                                в месяц
                                            </div>
                                            <input name="preferentialVisitsFourVisitsPerMonthWeekdayStudents" value={preferentialVisitsFourVisitsPerMonthWeekdayStudents} type="text"
                                                   className={'preferential-visits__four-visits__per-month-weekday__students'}
                                                   onChange={handleServiceChange}/>
                                            <input name="preferentialVisitsFourVisitsPerMonthWeekendsStaff" value={preferentialVisitsFourVisitsPerMonthWeekendsStaff} type="text"
                                                   className={'preferential-visits__four-visits__per-month-weekends__staff'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'preferential-visits__eight-visits__per-month'}>Абонемент на 8 посещений
                                                на 3 месяца
                                            </div>
                                            <input name="preferentialVisitsEightVisitsPerMonthWeekdayStudents" value={preferentialVisitsEightVisitsPerMonthWeekdayStudents} type="text"
                                                   className={'preferential-visits__eight-visits__per-month-weekday__students'}
                                                   onChange={handleServiceChange}/>
                                            <input name="preferentialVisitsEightVisitsPerMonthWeekendsStaff" value={preferentialVisitsEightVisitsPerMonthWeekendsStaff} type="text"
                                                   className={'preferential-visits__eight-visits__per-month-weekends__staff'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'preferential-visits__twelve-visits__per-month'}>Абонемент на 12
                                                посещений
                                                на 3 месяца
                                            </div>
                                            <input name="preferentialVisitsTwelveVisitsPerMonthWeekdayStudents" value={preferentialVisitsTwelveVisitsPerMonthWeekdayStudents} type="text"
                                                   className={'preferential-visits__twelve-visits__per-month-weekday__students'}
                                                   onChange={handleServiceChange}/>
                                            <input name="preferentialVisitsTwelveVisitsPerMonthWeekendsStaff" value={preferentialVisitsTwelveVisitsPerMonthWeekendsStaff} type="text"
                                                   className={'preferential-visits__twelve-visits__per-month-weekends__staff'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'pensioners'}>Пенсионеры</div>
                                            <div className={'pensioners__one-time__visit'}>Разовое посещение</div>
                                            <input name="pensionersOneTimeVisitStudents" value={pensionersOneTimeVisitStudents} type="text"
                                                   className={'pensioners__one-time__visit__students'}
                                                   onChange={handleServiceChange}/>
                                            <input name="pensionersOneTimeVisitStaff" value={pensionersOneTimeVisitStaff} type="text"
                                                   className={'pensioners__one-time__visit__staff'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'pensioners__four-visits__per-month'}>Абонемент на 4 посещения
                                                в месяц
                                            </div>
                                            <input name="pensionersFourVisitsPerMonthWeekdayStudents" value={pensionersFourVisitsPerMonthWeekdayStudents} type="text"
                                                   className={'pensioners__four-visits__per-month-weekday__students'}
                                                   onChange={handleServiceChange}/>
                                            <input name="pensionersFourVisitsPerMonthWeekendsStaff" value={pensionersFourVisitsPerMonthWeekendsStaff} type="text"
                                                   className={'pensioners__four-visits__per-month-weekends__staff'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'pensioners__eight-visits__per-month'}>Абонемент на 8 посещений
                                                на 3 месяца
                                            </div>
                                            <input name="pensionersEightVisitsPerMonthWeekdayStudents" value={pensionersEightVisitsPerMonthWeekdayStudents} type="text"
                                                   className={'pensioners__eight-visits__per-month-weekday__students'}
                                                   onChange={handleServiceChange}/>
                                            <input name="pensionersEightVisitsPerMonthWeekendsStaff" value={pensionersEightVisitsPerMonthWeekendsStaff} type="text"
                                                   className={'pensioners__eight-visits__per-month-weekends__staff'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'pensioners__twelve-visits__per-month'}>Абонемент на 12 посещений
                                                на 3 месяца
                                            </div>
                                            <input name="pensionersTwelveVisitsPerMonthWeekdayStudents" value={pensionersTwelveVisitsPerMonthWeekdayStudents} type="text"
                                                   className={'pensioners__twelve-visits__per-month-weekday__students'}
                                                   onChange={handleServiceChange}/>
                                            <input name="pensionersVisitsTwelveVisitsPerMonthWeekendsStaff" value={pensionersVisitsTwelveVisitsPerMonthWeekendsStaff} type="text"
                                                   className={'pensioners-visits__twelve-visits__per-month-weekends__staff'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'other-services'}>Прочие услуги</div>
                                            <div className={'other-services__six-tracks'}>Аренда бассейна, 6 дорожек</div>
                                            <input name="otherServicesSixTracksStudents" value={otherServicesSixTracksStudents} type="text"
                                                   className={'other-services__six-tracks__students'}
                                                   onChange={handleServiceChange}/>
                                            <input name="otherServicesSixTracksStaff" value={otherServicesSixTracksStaff} type="text"
                                                   className={'other-services__six-tracks__staff'}
                                                   onChange={handleServiceChange}/>
                                            <div className={'other-services__one-track'}>Аренда одной дорожки
                                            </div>
                                            <input name="otherServicesOneTrackStudents" value={otherServicesOneTrackStudents} type="text"
                                                   className={'other-services__one-track__students'}
                                                   onChange={handleServiceChange}/>
                                            <input name="otherServicesOneTrackStaff" value={otherServicesOneTrackStaff} type="text"
                                                   className={'other-services__one-track__staff'}
                                                   onChange={handleServiceChange}/>
                                            <input name="servicesTableText" value={servicesTableText} type="text"
                                                   className={'services-table__text'}
                                                   onChange={handleServiceChange}/>
                                        </div>
                                    </div>
                                    <div className='modal-footer'>
                                        <button type='submit' className='btn btn-success text-white'>
                                            Сохранить
                                        </button>
                                    </div>
                                </form>
                            </div>
                    </div>
            </div>
        </Fragment>
    );
};

export default AdminEditServices;