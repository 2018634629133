import React from "react";

const FooterCategories = () => {
    return (
        <div className="col-md-3 footer-grid">
            <h3>Навигация</h3>
            <ul>
                <li><a className="active" href={"#banner"}>Главная</a></li>
                <li><a href={"#about"} className="scroll">О нас</a></li>
                <li><a href={"#gallery"} className="scroll">Галерея</a></li>
                <li><a href={"#services"} className="scroll">Перечень услуг</a></li>
                <li><a href={"#contact"} className="scroll">Контакты</a></li>
            </ul>
        </div>
    )
}

export default FooterCategories