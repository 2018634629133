import axios from "axios"
import {START_LOADING, STOP_LOADING} from '../constants/loadingConstants'
import {SHOW_ERROR_MESSAGE, SHOW_SUCCESS_MESSAGE} from "../constants/messageConstants"
import {CREATE_CONTACT, GET_CONTACT, GET_CONTACTS, DELETE_CONTACTS} from "../constants/contactConstants";


export const createContact = formData => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        dispatch({type: START_LOADING})
        const response = await axios.post('/api/contact', formData, config)
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_SUCCESS_MESSAGE, payload: response.data.successMessage})
        dispatch({type: CREATE_CONTACT, payload: response.data.contact})
    } catch (err) {
        console.log('createContact api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const getContacts = () => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.get('/api/contact')
        // console.log(response);
        dispatch({type: STOP_LOADING})
        dispatch({type: GET_CONTACTS, payload: response.data.contacts})
    } catch (err) {
        console.log('getContacts api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const getContact = (contactId) => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.get(`/api/contact/${contactId}`)
        dispatch({type: STOP_LOADING})
        dispatch({type: GET_CONTACT, payload: response.data})
    } catch (err) {
        console.log('getContact api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const deleteContacts = (contactId) => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.delete(`/api/contact/${contactId}`)
        // console.log(response);
        dispatch({type: STOP_LOADING})
        dispatch({type: DELETE_CONTACTS, payload: response.data})
    } catch (err) {
        console.log('deleteContacts api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}