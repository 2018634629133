import axios from "axios"
import {START_LOADING, STOP_LOADING} from '../constants/loadingConstants'
import {SHOW_ERROR_MESSAGE, SHOW_SUCCESS_MESSAGE} from "../constants/messageConstants"
import {CREATE_GALLERY, DELETE_GALLERIES, GET_GALLERY, GET_GALLERIES} from "../constants/galleryConstants"

export const createGallery = formData => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.post('/api/gallery', formData)
        // console.log(response)
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_SUCCESS_MESSAGE, payload: response.data.successMessage})
        dispatch({type: CREATE_GALLERY, payload: response.data.gallery})
    } catch (err) {
        console.log('createGallery api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const getGalleries = () => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.get('/api/gallery')
        // console.log(response);
        dispatch({type: STOP_LOADING})
        dispatch({type: GET_GALLERIES, payload: response.data.galleries})
    } catch (err) {
        console.log('getGalleries api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const getGallery = (galleryId) => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.get(`/api/gallery/${galleryId}`)
        dispatch({type: STOP_LOADING})
        dispatch({type: GET_GALLERY, payload: response.data})
    } catch (err) {
        console.log('getGallery api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const deleteGalleries = (galleryId) => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.delete(`/api/gallery/${galleryId}`)
        // console.log(response);
        dispatch({type: STOP_LOADING})
        dispatch({type: DELETE_GALLERIES, payload: response.data})
    } catch (err) {
        console.log('deleteGalleries api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}