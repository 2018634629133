import React from "react";
import AdminHeaderCard from "./AdminHeaderCard";
import {useSelector} from "react-redux";
import AdminAboutCard from "./AdminAboutCard";
import AdminGalleryCard from "./AdminGalleryCard";
import AdminServicesCard from "./AdminServicesCard";
import AdminContactCard from "./AdminContactCard";

const AdminBody = () => {
    const {headers} = useSelector(state => state.headers)
    const {abouts} = useSelector(state => state.abouts)
    const {galleries} = useSelector(state => state.galleries)
    const {contacts} = useSelector(state => state.contacts)

    return (
        <div className='container'>
            <div className='row'>
            <div className="card-deck">
                <h2 className={'my-4'}>Редактировать Название и главную картинку</h2>
                <div className="card-deck">
                    {headers && headers.map((header) => (
                        <AdminHeaderCard key={header._id + Math.random()} header={header}/>
                    ))}
                </div>
                <hr className={'my-4'}/>
                <h2 className={'my-4'}>Редактировать блок О нас</h2>
                <div className="card-deck">
                    {abouts && abouts.map((about) => (
                        <AdminAboutCard key={about._id + Math.random()} about={about}/>
                    ))}
                </div>
                <hr className={'my-4'}/>
                <h2 className={'my-4'}>Редактировать блок Галерея</h2>
                <div className="card-deck d-flex flex-wrap">
                    {galleries && galleries.map((gallery) => (
                        <AdminGalleryCard key={gallery._id + Math.random()} gallery={gallery}/>
                    ))}
                </div>
                <hr className={'my-4'}/>
                <h2 className={'my-4'}>Редактировать блок Перечень услуг</h2>
                <div className="card-deck d-flex flex-wrap">
                        <AdminServicesCard />
                </div>
                <hr className={'my-4'}/>
                <h2 className={'my-4'}>Редактировать контакты</h2>
                <div className="card-deck d-flex flex-wrap mb-5">
                    {contacts && contacts.map((contact) => (
                    <AdminContactCard key={contact._id + Math.random()} contact={contact}/>
                    ))}
                </div>
            </div>
            </div>
        </div>
    )
}

export default AdminBody