import React from 'react';
import {useDispatch} from "react-redux";
import {deleteGalleries} from "../../redux/actions/galleryActions";
import {Link} from "react-router-dom";

const AdminGalleryCard = ({gallery}) => {
    const dispatch = useDispatch()

    return (
        // <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col-md-3 my-3 mx-2">
                <div className="card h-100">
                    <img src={`/assets/images/${gallery.fileName}`} className="img-fluid w-100" alt="header"/>
                    <div className="card-body text-center">
                        <Link to={`/admin/edit/gallery/${gallery._id}`} type={'button'}
                              className={'btn btn-secondary btn-sm me-2 my-1'}><i
                            className={'fa fa-edit pr-1'}/> Редактировать
                        </Link>
                        <button type={'button'} className={'btn btn-danger btn-sm'}
                                onClick={() => dispatch(deleteGalleries(gallery._id))}><i
                            className={'fa fa-trash pr-1'}/> Удалить
                        </button>
                    </div>
                </div>
            </div>
        // </div>
    );
}

export default AdminGalleryCard;