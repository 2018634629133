import React, { Fragment, useState } from 'react';
import isEmpty from "validator/es/lib/isEmpty";
import { showErrorMsg, showSuccessMsg } from "../../helpers/massage";
import { showLoading } from "../../helpers/loading";
import { useSelector, useDispatch } from 'react-redux'
import { clearMessages } from '../../redux/actions/messageActions'
import { createHeader } from '../../redux/actions/headerActions'

const AdminHeaderModal = () => {
    const { loading } = useSelector(state => state.loading)
    const { successMsg, errorMsg } = useSelector(state => state.messages)

    const dispatch = useDispatch()

    const [clientSideError, setClientSideError] = useState('')
    const [headerData, setHeaderData] = useState({
        headerImage: null,
        headerTitle: '',
    })

    const {
        headerImage,
        headerTitle,
    } = headerData

    const handleMessages = evt => {
        dispatch(clearMessages())
        setClientSideError('')
    }

    const handleHeaderImage = evt => {
        setHeaderData({
            ...headerData,
            [evt.target.name]: evt.target.files[0]
        })
    }

    const handleHeaderChange = evt => {
        setHeaderData({
            ...headerData,
            [evt.target.name]: evt.target.value,
        })
    }

    const handleHeaderSubmit = evt => {
        evt.preventDefault()

        if (headerImage === null) {
            setHeaderData({
                ...headerData
            })
            setClientSideError("Пожалуйста добавте изображение")
        } else if (isEmpty(headerTitle)) {
            setHeaderData({
                ...headerData
            })
            setClientSideError("Все поля обязательные")
        } else {
            let formData = new FormData()
            formData.append('headerImage', headerImage)
            formData.append('headerTitle', headerTitle)
            dispatch(createHeader(formData))
            setHeaderData({
                ...headerData,
                headerImage: null,
                headerTitle: ''
            })
        }
    }

    return (
        <div id='addHeaderModal' className='modal' onClick={handleMessages}>
            <div className='modal-dialog modal-dialog-centered modal-lg'>
                <div className='modal-content'>
                    <form onSubmit={handleHeaderSubmit}>
                        <div className='modal-header bg-info text-white'>
                            <h5 className='modal-title'>Изменить блок Header</h5>
                            <button type='button' className='close bg-transparent btn-outline-dark border-0'
                                data-bs-dismiss={'modal'}>
                                <span><i className={'fa fa-times'} /></span>
                            </button>
                        </div>
                        <div className='modal-body my-2'>
                            {clientSideError && showErrorMsg(clientSideError)}
                            {errorMsg && showErrorMsg(errorMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                            {
                                loading ? (
                                    showLoading()
                                ) : (
                                    <Fragment>
                                        <div className="mb-3">
                                            <label htmlFor="formFile" className="form-label">Фоновое изображение</label>
                                            <input className="form-control" name='headerImage'
                                                type="file" id="formFile" onChange={handleHeaderImage} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label htmlFor='headerTitle' className={'text-secondary'}>Название
                                                сайта</label>
                                            <input id="headerTitle" name="headerTitle" value={headerTitle} type="text"
                                                className={'form-control my-2'}
                                                onChange={handleHeaderChange} />
                                        </div>
                                    </Fragment>
                                )
                            }
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className={'btn btn-danger'} data-bs-dismiss={'modal'}>Закрыть
                            </button>
                            <button type='submit' className={'btn btn-success'}>Сохранить</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminHeaderModal;