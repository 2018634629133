import React, {useEffect, useState, Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getHeader} from "../../redux/actions/headerActions";
import AdminHeader from "./AdminHeader";
import {Link} from "react-router-dom";
import {updateHeader} from "../../api/header";

const AdminEditHeader = ({match, history}) => {
    const headerId = match.params.headerId

    const dispatch = useDispatch()
    const {header} = useSelector(state => state.headers)

    const [headerImage, setHeaderImage] = useState(null)
    const [headerTitle, setHeaderTitle] = useState('')


    useEffect(() => {
        if (!header) {
            dispatch(getHeader(headerId))
        } else {
            setHeaderImage(header.header.fileName)
            setHeaderTitle(header.header.headerTitle)
        }
    }, [dispatch, headerId, header])

    const handleImageUpload = evt => {
        const image = evt.target.files[0]
        setHeaderImage(image)
    }

    const handleHeaderSubmit = async evt => {
        evt.preventDefault()

        const formData = new FormData()
        formData.append('headerImage', headerImage)
        formData.append('headerTitle', headerTitle)

        updateHeader(formData, headerId)
            .then(res => {
                history.push('/admin/dashboard')
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <Fragment>
            <AdminHeader headerTitle={headerTitle}/>
            <div className='container my-3'>
                <div className='row'>
                    <div className='col-md-8 mx-auto'>
                        <Link to='/admin/dashboard'>
                            <span className='fa fa-arrow-left'>Назад</span>
                        </Link>
                        <div>
                            <br/>
                            <div className='modal-content'>
                                <form onSubmit={handleHeaderSubmit}>
                                    <div className='modal-header bg-info text-white'>
                                        <h5 className='modal-title'>
                                            Изменить название и главную картинку
                                        </h5>
                                    </div>
                                    <div className='modal-body my-2'>
                                        <Fragment>
                                            <label className='btn btn-dark me-4'>
                                                Загрузить картинку
                                                <input
                                                    type='file'
                                                    name='headerImage'
                                                    accept='images/*'
                                                    hidden
                                                    onChange={handleImageUpload}
                                                />
                                            </label>
                                            {headerImage && headerImage.name ? (
                                                <span className='badge bg-secondary'>
													{headerImage.name}
												</span>
                                            ) : headerImage ? (
                                                <img
                                                    className='img-thumbnail'
                                                    style={{
                                                        width: '120px',
                                                        height: '80px',
                                                    }}
                                                    src={`/assets/images/${headerImage}`}
                                                    alt='product'
                                                />
                                            ) : null}

                                            <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Название сайта
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='headerTitle'
                                                    value={headerTitle}
                                                    onChange={e => setHeaderTitle(e.target.value)}
                                                />
                                            </div>
                                        </Fragment>
                                    </div>
                                    <div className='modal-footer'>
                                        <button type='submit' className='btn btn-success text-white'>
                                            Сохранить
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AdminEditHeader;