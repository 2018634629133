import React, {useEffect} from "react";
import About from "./About/About";
import Gallery from "./Gallery/Gallery";
import Services from "./Services/Services";
import Contact from "./Contact/Contact";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Copyrigth from "./Copyrigth";
import ScrollTop from '@nzambello/react-scrolltop'
import {useDispatch} from "react-redux";
import {getHeaders} from "../redux/actions/headerActions";
import {getAbouts} from "../redux/actions/aboutActions";
import {getGalleries} from "../redux/actions/galleryActions";
import {getServices} from "../redux/actions/serviceActions";
import {getContacts} from "../redux/actions/contactActions";

const Home = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getHeaders())
        dispatch(getAbouts())
        dispatch(getGalleries())
        dispatch(getServices())
        dispatch(getContacts())
    }, [dispatch])
    return (
        <>
            <Header/>
            <About/>
            <Gallery/>
            <Services/>
            <Contact/>
            <Footer/>
            <Copyrigth/>
            <ScrollTop/>
        </>
    )
}

export default Home