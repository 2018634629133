import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import AdminHeader from "./AdminHeader";
import {Link} from "react-router-dom";
import {updateContact} from "../../api/contact";
import {getContact} from "../../redux/actions/contactActions";

const AdminEditContact = ({match, history}) => {
    const contactId = match.params.contactId

    const dispatch = useDispatch()
    const {contact} = useSelector(state => state.contacts)
    const [contactData, setContactData] = useState({
        address: '',
        phone: '',
        email: '',
        info: '',
    })

    const {address, phone, email, info} = contactData

    useEffect(() => {
        if (!contact) {
            dispatch(getContact(contactId))
        } else {
            setContactData({
                ...contactData,
                address: contact.contact.address,
                phone: contact.contact.phone,
                email: contact.contact.email,
                info: contact.contact.info,
            })
        }
    }, [dispatch, contactId, contact])

    const handleContactChange = evt => {
        setContactData({
            ...contactData,
            [evt.target.name]: evt.target.value,
        })
    }

    const handleContactSubmit = async evt => {
        evt.preventDefault()

        const data = {
            address,
            phone,
            email,
            info,
        }
        updateContact(data, contactId)
            .then(res => {
                history.push('/admin/dashboard')
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <Fragment>
            <AdminHeader/>
            <div className='container my-3'>
                <div className='row'>
                    <div className='col-md-8 mx-auto'>
                        <Link to='/admin/dashboard'>
                            <span className='fa fa-arrow-left'>Назад</span>
                        </Link>
                        <div>
                            <br/>
                            <div className='modal-content'>
                                <form onSubmit={handleContactSubmit}>
                                    <div className='modal-header bg-info text-white'>
                                        <h5 className='modal-title'>
                                            Редактировать контакты
                                        </h5>
                                    </div>
                                    <div className='modal-body my-2'>
                                        <Fragment>
                                            <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Адресс
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='address'
                                                    value={address}
                                                    onChange={handleContactChange}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Номер телефона
                                                </label>
                                                <input
                                                    type='phone'
                                                    className='form-control'
                                                    name='phone'
                                                    value={phone}
                                                    onChange={handleContactChange}
                                                />
                                            </div>
                                              <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Email
                                                </label>
                                                <input
                                                    type='email'
                                                    className='form-control'
                                                    name='email'
                                                    value={email}
                                                    onChange={handleContactChange}
                                                />
                                            </div>
                                              <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Дополнительная информация
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='info'
                                                    value={info}
                                                    onChange={handleContactChange}
                                                />
                                            </div>

                                        </Fragment>
                                    </div>
                                    <div className='modal-footer'>
                                        <button type='submit' className='btn btn-success text-white'>
                                            Сохранить
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AdminEditContact;