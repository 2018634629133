import React from "react";

const ContactAddress = ({address, phone, email}) => {
    return (
        <div className="address">
            <div className="col-sm-4 address-grids">
                <h4>Адрес :</h4>
                <p>{address}</p>
            </div>
            <div className="col-sm-4 address-grids">
                <h4>Телефон :</h4>
                <p><a href={`tel:${phone}`}>{phone}</a></p>
            </div>
            <div className="col-sm-4 address-grids">
                <h4>Email :</h4>
                <p><a href={`mailto:${email}`}>{email}</a></p>
            </div>
            <div className="clearfix"/>
        </div>
    )
}

export default ContactAddress