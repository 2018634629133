import axios from "axios";

export const createContact = async (data) => {

    return await axios.post('/api/contact', data)
}
export const updateContact = async (data, contactId) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return await axios.put(`/api/contact/${contactId}`, data, config)
}

