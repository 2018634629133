import React from "react";
import {Link} from "react-router-dom";

const HeaderLogo = ({header}) => {
    return (
        <div className="w3layouts-logo">
            <h1>
                <Link className="navbar-brand" to={"/"}>{header.headerTitle}</Link>
            </h1>
        </div>
    )
}

export default HeaderLogo