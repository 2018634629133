import React, {Component} from "react";
import Gallery from "react-grid-gallery";

export default class GalleryImages extends Component {
    imageThumbnails = image => {
        return new Promise((resolve) => {
            let img = new Image();
            img.src = image.thumbnail;
            img.onload = () => {
                // image.thumbnailHeight = img.height;
                // image.thumbnailWidth = img.width;
                resolve("ok");
                // console.log(image.thumbnailHeight)
            };
        });
    };
    galleryImg = () => {
        return this.props.galleries.map(gallery => {
            return {
                src: `/assets/images/${gallery.fileName}`,
                thumbnail: `/assets/images/${gallery.fileName}`,
                thumbnailHeight: 180,
                thumbnailWidth: 270
            }
        })
    }

    componentDidMount() {
        const p = this.galleryImg().map(this.imageThumbnails);
        Promise.all(p)
            .then(
                result => {
                    //console.log('before state', result);
                }
            )
            .catch((error) => {
                console.log("error ", error);
            })
    }

    render() {
        return <Gallery images={this.galleryImg()} backdropClosesModal={true}/>
    }
}
