import {CREATE_SERVICE, GET_SERVICE, GET_SERVICES, DELETE_SERVICES} from "../constants/serviceConstants";


const INITIAL_STATE = {
    services: []
}

const serviceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_SERVICE:
            return {
                services: [...state.services, action.payload]
            }
        case GET_SERVICES:
            return {
                services: [...action.payload]
            }
        case GET_SERVICE:
            return {
                service: action.payload
            }
        case DELETE_SERVICES:
            return {
                services: state.services.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    }
}

export default serviceReducer