import React from "react";
import FooterCategories from "./FooterCategories";
import FooterMap from "./FooterMap";

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-top'>
                <div className='container'>
                    <div className='footer-grids footer-container'>
                        <div className='col-md-4 footer-grid'>
                            <div className='footer-logo'>
                                <h3><a href={'#banner'} v-scroll-to="`#banner`">Бассейн ПНИПУ</a></h3>
                            </div>
                        </div>
                        <FooterMap/>
                        <FooterCategories/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer