import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./Home";
import NotFound from "./404"
import Signin from "./Signin";
// import Signup from "./Signup";
import AdminRoute from "./Admin/AdminRoute";
import AdminDashboard from "./Admin/AdminDashboard"
import AdminEditHeader from "./Admin/AdminEditHeader";
import AdminEditAbout from "./Admin/AdminEditAbout";
import AdminEditGallery from "./Admin/AdminEditGallery";
import AdminEditServices from "./Admin/AdminEditServices";
import AdminEditContact from "./Admin/AdminEditContact";

const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route exact path='/admin' component={Signin}/>
                {/*<Route exact path='/signup' component={Signup}/>*/}
                <AdminRoute exact path='/admin/dashboard' component={AdminDashboard}/>
                <AdminRoute exact path='/admin/edit/header/:headerId' component={AdminEditHeader}/>
                <AdminRoute exact path='/admin/edit/about/:aboutId' component={AdminEditAbout}/>
                <AdminRoute exact path='/admin/edit/gallery/:galleryId' component={AdminEditGallery}/>
                <AdminRoute exact path='/admin/edit/service/:serviceId' component={AdminEditServices}/>
                <AdminRoute exact path='/admin/edit/contact/:contactId' component={AdminEditContact}/>
                <Route component={NotFound}/>
            </Switch>
        </BrowserRouter>
    )
}

export default App;
