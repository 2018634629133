import React, { Fragment, useEffect } from 'react';
import AdminHeader from "./AdminHeader";
import AdminActionBtns from "./AdminActionBtns";
import AdminBody from './AdminBody'
import { useDispatch } from 'react-redux';
import { getHeaders } from '../../redux/actions/headerActions';
import {getAbouts} from "../../redux/actions/aboutActions";
import {getGalleries} from "../../redux/actions/galleryActions";
import {getServices} from "../../redux/actions/serviceActions";
import {getContacts} from "../../redux/actions/contactActions";

const AdminDashboard = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getHeaders())
        dispatch(getAbouts())
        dispatch(getGalleries())
        dispatch(getServices())
        dispatch(getContacts())
    }, [dispatch])


    return (
        <Fragment>
            <header className='admin-header'>
                <AdminHeader />
            </header>
            <main className="admin-main">
                <AdminActionBtns />
                <AdminBody />
            </main>
            <footer className="admin-footer"></footer>
        </Fragment>
    );
};

export default AdminDashboard;