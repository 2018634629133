import React, {Fragment} from "react";
import ContactAddress from "./ContactAddress";
import ContactInformation from "./ContactInformation";
import ContactForm from "./ContactForm";
import {useSelector} from "react-redux";

const Contact = () => {
    const {contacts} = useSelector(state => state.contacts)
    return (
        <div className="contact" id="contact">
            <div className="container">
                <div className="about-heading">
                    <h3>Наши контакты</h3>
                </div>
                {contacts && contacts.map(contact => (
                    <Fragment key={contact._id + Math.random()}>
                        <ContactAddress key={contact._id + Math.random()} address={contact.address} phone={contact.phone} email={contact.email}/>
                        <ContactInformation key={contact._id + Math.random()} info={contact.info}/>
                    </Fragment>
                ))}
                <ContactForm/>
            </div>
        </div>
    )
}

export default Contact