import axios from "axios";

export const createService = async (data) => {

    return await axios.post('/api/service', data)
}
export const updateService = async (data, serviceId) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return await axios.put(`/api/service/${serviceId}`, data, config)
}

