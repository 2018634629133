import axios from "axios";

export const createHeader = async (data) => {

    return await axios.post('/api/header', data)
}
export const updateHeader = async (data, headerId) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    return await axios.put(`/api/header/${headerId}`, data, config)
}

