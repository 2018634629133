import React, {useState} from "react";
import {Link} from "react-router-dom";
import {deleteServices} from "../../redux/actions/serviceActions";
import {useDispatch, useSelector} from "react-redux";


const AdminServicesCard = () => {

    const {services} = useSelector(state => state.services)
    const dispatch = useDispatch()

    return (
        <div className="services-card">
            <div className="services-wrap">
                <div className="services-right">
                    <ul className={'services-list'}>
                        {services && services.map((service, index) => (
                            <>
                                <div className={'services-table'} key={service._id + Math.random()}>
                                    <span className={'services-table__head'}>{service.servicesTableHead}</span>
                                    <div className={'services-list'}>Перечень услуг</div>
                                    <div className={'services-price'}>Стоимость услуг (руб.)</div>
                                    <div className={'services-adult'}>Для взрослых</div>
                                    <div className={'services-child'}>Для детей (до 14лет в сопровождении взрослых)
                                    </div>
                                    <div className={'services-weekday__adult'}>Будни</div>
                                    <div className={'services-weekends__adult'}>Выходные и праздничные дни</div>
                                    <div className={'services-weekday__child'}>Будни</div>
                                    <div className={'services-weekends__child'}>Выходные и праздничные дни</div>
                                    <div className={'free-swimming'}>Свободное плавание (45 мин.)</div>
                                    <div className={'free-swimming__one-time__visit'}>Разовое посещение</div>
                                    <div
                                        className={'free-swimming__one-time__visit-weekday__adult'}>{service.freeSwimmingOneTimeVisitWeekdayAdult}</div>
                                    <div
                                        className={'free-swimming__one-time__visit-weekends__adult'}>{service.freeSwimmingOneTimeVisitWeekendsAdult}</div>
                                    <div
                                        className={'free-swimming__one-time__visit-weekday__child'}>{service.freeSwimmingOneTimeVisitWeekdayChild}</div>
                                    <div
                                        className={'free-swimming__one-time__visit-weekends__child'}>{service.freeSwimmingOneTimeVisitWeekendsChild}</div>
                                    <div className={'free-swimming__four-visits__per-month'}>Абонемент на 4 посещения в
                                        месяц
                                    </div>
                                    <div
                                        className={'free-swimming__four-visits__per-month-weekday__adult'}>{service.freeSwimmingFourVisitsPerMonthWeekdayAdult}</div>
                                    <div
                                        className={'free-swimming__four-visits__per-month-weekends__adult'}>{service.freeSwimmingFourVisitsPerMonthWeekendsAdult}</div>
                                    <div
                                        className={'free-swimming__four-visits__per-month-weekday__child'}>{service.freeSwimmingFourVisitsPerMonthWeekdayChild}</div>
                                    <div
                                        className={'free-swimming__four-visits__per-month-weekends__child'}>{service.freeSwimmingFourVisitsPerMonthWeekendsChild}</div>
                                    <div className={'free-swimming__eight-visits__per-month'}>Абонемент на 8 посещения в
                                        месяц
                                    </div>
                                    <div
                                        className={'free-swimming__eight-visits__per-month-weekday__adult'}>{service.freeSwimmingEightVisitsPerMonthWeekdayAdult}</div>
                                    <div
                                        className={'free-swimming__eight-visits__per-month-weekends__adult'}>{service.freeSwimmingEightVisitsPerMonthWeekendsAdult}</div>
                                    <div
                                        className={'free-swimming__eight-visits__per-month-weekday__child'}>{service.freeSwimmingEightVisitsPerMonthWeekdayChild}</div>
                                    <div
                                        className={'free-swimming__eight-visits__per-month-weekends__child'}>{service.freeSwimmingEightVisitsPerMonthWeekendsChild}</div>
                                    <div className={'free-swimming__twelve-visits__per-month'}>Абонемент на 12 посещений
                                        в
                                        месяц
                                    </div>
                                    <div
                                        className={'free-swimming__twelve-visits__per-month-weekday__adult'}>{service.freeSwimmingTwelveVisitsPerMonthWeekdayAdult}</div>
                                    <div
                                        className={'free-swimming__twelve-visits__per-month-weekends__adult'}>{service.freeSwimmingTwelveVisitsPerMonthWeekendsAdult}</div>
                                    <div
                                        className={'free-swimming__twelve-visits__per-month-weekday__child'}>{service.freeSwimmingTwelveVisitsPerMonthWeekdayChild}</div>
                                    <div
                                        className={'free-swimming__twelve-visits__per-month-weekends__child'}>{service.freeSwimmingTwelveVisitsPerMonthWeekendsChild}</div>
                                    <div className={'preferential-visits'}>Льготные посещения для студентов ПНИПУ очной
                                        формы обучения, аспирантов, сотрудников ПНИПУ и бывших работников ПНИПУ, имеющих
                                        звание "Ветеран ПНИПУ" (45 мин.)
                                    </div>
                                    <div className={'preferential-visits__students'}>Студенты, Аспиранты</div>
                                    <div className={'preferential-visits__staff'}>Сотрудники, "Ветераны ПНИПУ"</div>
                                    <div className={'preferential-visits__one-time__visit'}>Разовое посещение</div>
                                    <div
                                        className={'preferential-visits__one-time__visit__students'}>{service.preferentialVisitsOneTimeVisitStudents}</div>
                                    <div
                                        className={'preferential-visits__one-time__visit__staff'}>{service.preferentialVisitsOneTimeVisitStaff}</div>
                                    <div className={'preferential-visits__four-visits__per-month'}>Абонемент на 4
                                        посещения
                                        в месяц
                                    </div>
                                    <div
                                        className={'preferential-visits__four-visits__per-month-weekday__students'}>{service.preferentialVisitsFourVisitsPerMonthWeekdayStudents}
                                    </div>
                                    <div
                                        className={'preferential-visits__four-visits__per-month-weekends__staff'}>{service.preferentialVisitsFourVisitsPerMonthWeekendsStaff}
                                    </div>
                                    <div className={'preferential-visits__eight-visits__per-month'}>Абонемент на 8
                                        посещений
                                        на 3 месяца
                                    </div>
                                    <div
                                        className={'preferential-visits__eight-visits__per-month-weekday__students'}>{service.preferentialVisitsEightVisitsPerMonthWeekdayStudents}
                                    </div>
                                    <div
                                        className={'preferential-visits__eight-visits__per-month-weekends__staff'}>{service.preferentialVisitsEightVisitsPerMonthWeekendsStaff}
                                    </div>
                                    <div className={'preferential-visits__twelve-visits__per-month'}>Абонемент на 12
                                        посещений
                                        на 3 месяца
                                    </div>
                                    <div
                                        className={'preferential-visits__twelve-visits__per-month-weekday__students'}>{service.preferentialVisitsTwelveVisitsPerMonthWeekdayStudents}
                                    </div>
                                    <div
                                        className={'preferential-visits__twelve-visits__per-month-weekends__staff'}>{service.preferentialVisitsTwelveVisitsPerMonthWeekendsStaff}
                                    </div>
                                    <div className={'pensioners'}>Пенсионеры</div>
                                    <div className={'pensioners__one-time__visit'}>Разовое посещение</div>
                                    <div
                                        className={'pensioners__one-time__visit__students'}>{service.pensionersOneTimeVisitStudents}</div>
                                    <div
                                        className={'pensioners__one-time__visit__staff'}>{service.pensionersOneTimeVisitStaff}</div>
                                    <div className={'pensioners__four-visits__per-month'}>Абонемент на 4 посещения
                                        в месяц
                                    </div>
                                    <div
                                        className={'pensioners__four-visits__per-month-weekday__students'}>{service.pensionersFourVisitsPerMonthWeekdayStudents}
                                    </div>
                                    <div
                                        className={'pensioners__four-visits__per-month-weekends__staff'}>{service.pensionersFourVisitsPerMonthWeekendsStaff}
                                    </div>
                                    <div className={'pensioners__eight-visits__per-month'}>Абонемент на 8 посещений
                                        на 3 месяца
                                    </div>
                                    <div
                                        className={'pensioners__eight-visits__per-month-weekday__students'}>{service.pensionersEightVisitsPerMonthWeekdayStudents}
                                    </div>
                                    <div
                                        className={'pensioners__eight-visits__per-month-weekends__staff'}>{service.pensionersEightVisitsPerMonthWeekendsStaff}
                                    </div>
                                    <div className={'pensioners__twelve-visits__per-month'}>Абонемент на 12 посещений
                                        на 3 месяца
                                    </div>
                                    <div
                                        className={'pensioners__twelve-visits__per-month-weekday__students'}>{service.pensionersTwelveVisitsPerMonthWeekdayStudents}
                                    </div>
                                    <div
                                        className={'pensioners-visits__twelve-visits__per-month-weekends__staff'}>{service.pensionersVisitsTwelveVisitsPerMonthWeekendsStaff}
                                    </div>
                                    <div className={'other-services'}>Прочие услуги</div>
                                    <div className={'other-services__six-tracks'}>Аренда бассейна, 6 дорожек</div>
                                    <div
                                        className={'other-services__six-tracks__students'}>{service.otherServicesSixTracksStudents}</div>
                                    <div
                                        className={'other-services__six-tracks__staff'}>{service.otherServicesSixTracksStaff}</div>
                                    <div className={'other-services__one-track'}>Аренда одной дорожки
                                    </div>
                                    <div
                                        className={'other-services__one-track__students'}>{service.otherServicesOneTrackStudents}
                                    </div>
                                    <div
                                        className={'other-services__one-track__staff'}>{service.otherServicesOneTrackStaff}
                                    </div>
                                    <span className={'services-table__text'}>{service.servicesTableText}</span>
                                </div>
                                <div className={'d-block text-right'}>
                                    <Link to={`/admin/edit/service/${service._id}`} type={'button'}
                                          className={'btn btn-secondary me-2 my-1 pull-right'}><i
                                        className={'fa fa-edit pr-1'}/>
                                    </Link>
                                    {/*<button type={'button'} className={'btn btn-danger me-2 my-1 pull-right'}*/}
                                    {/*        onClick={() => dispatch(deleteServices(service._id))}><i*/}
                                    {/*    className={'fa fa-trash pr-1'}/>*/}
                                    {/*</button>*/}
                                </div>
                            </>
                        ))}
                    </ul>
                </div>
            </div>
            {/*    <Fragment>*/}
            {/*<Link to={`/admin/edit/service/${service._id}`} type={'button'}*/}
            {/*      className={'btn btn-secondary btn-sm me-2 my-1'}><i*/}
            {/*    className={'fa fa-edit pr-1'}/> Редактировать*/}
            {/*</Link>*/}
            {/*/!*<button type={'button'} className={'btn btn-danger btn-sm'}*!/*/}
            {/*/!*        onClick={() => dispatch(deleteHeaders(header._id))}><i*!/*/}
            {/*/!*    className={'fa fa-trash pr-1'}/> Удалить*!/*/}
            {/*/!*</button>*!/*/}
            {/*    </Fragment>*/}
        </div>
    );
}
export default AdminServicesCard

