import React, {Fragment, useState} from 'react';
import {showErrorMsg, showSuccessMsg} from "../../helpers/massage";
import {showLoading} from "../../helpers/loading";
import {useDispatch, useSelector} from "react-redux";
import {clearMessages} from "../../redux/actions/messageActions";
import isEmpty from "validator/es/lib/isEmpty";
import {createContact} from "../../redux/actions/contactActions";

const AdminContactModal = () => {

    const {loading} = useSelector(state => state.loading)
    const {successMsg, errorMsg} = useSelector(state => state.messages)

    const dispatch = useDispatch()

    const [clientSideError, setClientSideError] = useState('')
    const [contactData, setContactData] = useState({
        address: '',
        phone: '',
        email: '',
        info: '',
    })

    const {address, phone, email, info} = contactData

    const handleMessages = evt => {
        dispatch(clearMessages())
        setClientSideError('')
    }

    const handleContactChange = evt => {
        setContactData({
            ...contactData,
            [evt.target.name]: evt.target.value,
        })
    }

    const handleContactSubmit = evt => {
        evt.preventDefault()

        if (isEmpty(address) || isEmpty(phone)|| isEmpty(email)|| isEmpty(info)) {
            setContactData({
                ...contactData
            })
            setClientSideError("Все поля обязательные")
        } else {

            const data = {
                address,
                phone,
                email,
                info,
            }

            dispatch(createContact(data))
            setContactData({
                ...contactData,
                address: '',
                phone: '',
                email: '',
                info: '',
            })
        }
    }
    return (
        <div id='addContactModal' className='modal' onClick={handleMessages}>
            <div className='modal-dialog modal-dialog-centered modal-lg'>
                <div className='modal-content'>
                    <form onSubmit={handleContactSubmit}>
                        <div className='modal-header bg-info text-white'>
                            <h5 className='modal-title'>Добавить контактную информацию</h5>
                            <button type='button' className='close bg-transparent btn-outline-dark border-0'
                                    data-bs-dismiss={'modal'}>
                                <span><i className={'fa fa-times'}/></span>
                            </button>
                        </div>
                        <div className='modal-body my-2'>
                            {clientSideError && showErrorMsg(clientSideError)}
                            {errorMsg && showErrorMsg(errorMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                            {
                                loading ? (
                                    showLoading()
                                ) : (
                                    <Fragment>
                                        <div className={'form-group'}>
                                            <label htmlFor='address' className={'text-secondary'}>Адресс</label>
                                            <input id="address" name="address" value={address} type="address"
                                                   className={'form-control my-2'}
                                                   onChange={handleContactChange}/>
                                        </div>
                                        <div className={'form-group'}>
                                            <label htmlFor='phone' className={'text-secondary'}>Телефон</label>
                                            <input id="phone" name="phone" value={phone} type="phone"
                                                   className={'form-control my-2'}
                                                   onChange={handleContactChange}/>
                                        </div>
                                        <div className={'form-group'}>
                                            <label htmlFor='email' className={'text-secondary'}>Email</label>
                                            <input id="email" name="email" value={email} type="email"
                                                   className={'form-control my-2'}
                                                   onChange={handleContactChange}/>
                                        </div>
                                        <div className={'form-group'}>
                                            <label htmlFor='info' className={'text-secondary'}>Дополнительная информация</label>
                                            <input id="info" name="info" value={info} type="text"
                                                   className={'form-control my-2'}
                                                   onChange={handleContactChange}/>
                                        </div>
                                    </Fragment>
                                )
                            }
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className={'btn btn-danger'} data-bs-dismiss={'modal'}>Закрыть
                            </button>
                            <button type='submit' className={'btn btn-success'}>Сохранить</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminContactModal;