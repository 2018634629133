import React from "react";
import AboutIconAndText from "./AboutIconAndText";
import {useSelector} from "react-redux";

const About = () => {
    const {abouts} = useSelector(state => state.abouts)
    return (
        <div className="about" id="about">
            <div className="container">
                <div className="about-heading">
                    <h3>о нас</h3>
                </div>
                {abouts && abouts.map((about) => (
                    <div className="w3l-about-grids" key={`${about._id} ${Math.random()}`}>
                        <AboutIconAndText key={`${about._id}${Math.random()}`} about={about}/>
                        <div className="col-md-6 w3ls-about-right">
                            <div
                                key={`${about._id} ${Math.random()}`}
                                className="w3ls-about-right-img"
                                style={{backgroundImage: `url("/assets/images/${about.fileName}")`}}>
                            </div>
                        </div>
                        <div className="clearfix"/>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default About