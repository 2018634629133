import React, {Fragment} from 'react';
// import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
// import {deleteAbouts} from "../../redux/actions/aboutActions";

const AdminAboutCard = ({about}) => {
    // const dispatch = useDispatch()

    return (
        <Fragment>
            <div className="w3l-about-grids">
                <div className="col-md-6 w3ls-about-left">
                    <div className={"agileits-icon-grid"}>
                        <div className={"icon-left hvr-radial-out"}>
                            <i className={"fa fa-cog"} aria-hidden="true"/>
                        </div>
                        <div className={"icon-right"}>
                            <h5>{about.aboutHeadOne}</h5>
                            <p>{about.aboutTextOne}</p>
                        </div>
                        <div className={"clearfix"}/>
                    </div>
                    <div className={"agileits-icon-grid"}>
                        <div className={"icon-left hvr-radial-out"}>
                            <i className={"fa fa-heart"} aria-hidden="true"/>
                        </div>
                        <div className={"icon-right"}>
                            <h5>{about.aboutHeadTwo}</h5>
                            <p>{about.aboutTextTwo}</p>
                        </div>
                        <div className={"clearfix"}/>
                    </div>
                    <div className={"agileits-icon-grid"}>
                        <div className={"icon-left hvr-radial-out"}>
                            <i className={"fa fa-paper-plane"} aria-hidden="true"/>
                        </div>
                        <div className={"icon-right"}>
                            <h5>{about.aboutHeadThree}</h5>
                            <p>{about.aboutTextThree}</p>
                        </div>
                        <div className={"clearfix"}/>
                    </div>
                </div>
                <div className="col-md-6 w3ls-about-right">
                    <div className="w3ls-about-right-img" style={{backgroundImage: `url("/assets/images/${about.fileName}")`}}>
                    </div>
                </div>
                <div className="clearfix"/>
            </div>
            <div className={'my-4 text-center'}>
                <Link to={`/admin/edit/about/${about._id}`} type={'button'}
                      className={'btn btn-secondary btn-sm me-2 my-1'}><i
                    className={'fa fa-edit pr-1'}/> Редактировать
                </Link>
                {/*<button type={'button'} className={'btn btn-danger btn-sm'}*/}
                {/*        onClick={() => dispatch(deleteAbouts(about._id))}><i*/}
                {/*    className={'fa fa-trash pr-1'}/> Удалить*/}
                {/*</button>*/}
            </div>
        </Fragment>
    );
}

export default AdminAboutCard;