import React from 'react';
import {logout} from "../../helpers/auth";
import {Link, withRouter} from "react-router-dom";
import {useSelector} from "react-redux";

const AdminHeader = ({history, headerTitle}) => {
    let {headers} = useSelector(state => state.headers)
    const handleLogout = (evt) => {
        logout(() => {
            history.push('/admin')
        })
    }
    return (
        <nav className="navbar navbar-light bg-light">
            <div className="container d-flex justify-content-between">
                <div className="w3layouts-logo">
                    <h1>
                        {headerTitle &&
                        <Link key={Math.random()} className="navbar-brand" to={"/"}>{headerTitle}</Link>}

                        {headers && headers.map(header => (
                            <Link key={header._id + Math.random()} className="navbar-brand"
                                  to={"/"}>{header.headerTitle}</Link>
                        ))}

                    </h1>
                </div>
                <button className="btn btn-outline-success mr-2" type="button" onClick={handleLogout}>Выход</button>
            </div>
        </nav>
    );
};

export default withRouter(AdminHeader);