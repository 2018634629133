import axios from "axios"
import {CREATE_HEADER, GET_HEADERS, DELETE_HEADERS, GET_HEADER} from "../constants/headerConstants"
import {START_LOADING, STOP_LOADING} from '../constants/loadingConstants'
import {SHOW_ERROR_MESSAGE, SHOW_SUCCESS_MESSAGE} from "../constants/messageConstants"

export const createHeader = formData => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.post('/api/header', formData)
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_SUCCESS_MESSAGE, payload: response.data.successMessage})
        dispatch({type: CREATE_HEADER, payload: response.data.header})
    } catch (err) {
        console.log('createHeader api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const getHeaders = () => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.get('/api/header')
        // console.log(response);
        dispatch({type: STOP_LOADING})
        dispatch({type: GET_HEADERS, payload: response.data.headers})
    } catch (err) {
        console.log('getHeaders api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const getHeader = (headerId) => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.get(`/api/header/${headerId}`)
        dispatch({type: STOP_LOADING})
        dispatch({type: GET_HEADER, payload: response.data})
    } catch (err) {
        console.log('getHeader api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}

export const deleteHeaders = (headerId) => async dispatch => {
    try {
        dispatch({type: START_LOADING})
        const response = await axios.delete(`/api/header/${headerId}`)
        // console.log(response);
        dispatch({type: STOP_LOADING})
        dispatch({type: DELETE_HEADERS, payload: response.data})
    } catch (err) {
        console.log('deleteHeader api error: ', err);
        dispatch({type: STOP_LOADING})
        dispatch({type: SHOW_ERROR_MESSAGE, payload: err.response.data.errorMessage})
    }
}