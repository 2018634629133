import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {showErrorMsg} from "../helpers/massage";
import {showLoading} from "../helpers/loading";
import {isAuthenticated, setAuthentication} from '../helpers/auth'
import isEmpty from "validator/es/lib/isEmpty";
import {signin} from "../api/auth";

const Signin = () => {
    let history = useHistory()
    useEffect(() => {
        if (isAuthenticated() && isAuthenticated().role === 1) {
            history.push('/admin/dashboard')
        } else if (isAuthenticated() && isAuthenticated().role === 0) {
            history.push('/')
        }
    }, [history])
    const [formData, setFormData] = useState({
        login: '',
        password: '',
        errorMsg: false,
        loading: false
    })

    const {login, password, errorMsg, loading} = formData

    const handleChange = evt => {
        setFormData({
            ...formData,
            [evt.target.name]: evt.target.value,
            errorMsg: ''
        })
    }
    const handleSubmit = evt => {
        evt.preventDefault()
        if (isEmpty(login) || isEmpty(password)) {
            setFormData({
                ...formData, errorMsg: "Все поля обязательные"
            })
        } else {
            const {login, password} = formData
            const data = {login, password}

            setFormData({
                ...formData, loading: true
            })

            signin(data)
                .then(response => {
                    setAuthentication(response.data.token, response.data.user)

                    if (isAuthenticated() &&  isAuthenticated().role === 1) {
                        history.push('/admin/dashboard')
                    } else {
                        history.push('/')
                    }
                })
                .catch(err => {
                    // console.log('Signin api function error: ', err)
                    setFormData({
                        ...formData,
                        loading: false,
                        errorMsg: err.response.data.errorMessage,
                    });
                })
        }
    }
    return (
        <div className="wrapper2 fadeInDown">
            <div id="formContent">
                <h2 className="active"> Вход </h2>
                {errorMsg && showErrorMsg(errorMsg)}
                {loading && showLoading()}
                <form onSubmit={handleSubmit} noValidate>
                    <input type="text" id="login" className="login fadeIn second" value={login} name="login"
                           placeholder="Логин" onChange={handleChange}/>
                    <input type="password" id="password" className="password fadeIn third" value={password}
                           name="password"
                           placeholder="Пароль" onChange={handleChange}/>
                    <input type="submit" className="enter fadeIn fourth" value="Вход"/>
                </form>
            </div>
        </div>
    )
}

export default Signin