import React from "react";

const FooterMap = () => {
    return (
        <div className="footer-map">
            <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A18dbefe09294e0337ccba08679fa568d8c7529727a0d838b6f30a21937185480&amp;source=constructor"
                width="320" height="240" frameBorder="0"/>
        </div>
    )
}

export default FooterMap