import React, {useEffect, useState, Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import AdminHeader from "./AdminHeader";
import {Link} from "react-router-dom";
import {getAbout} from "../../redux/actions/aboutActions";
import {updateAbout} from "../../api/about";

const AdminEditAbout = ({match, history}) => {
    const aboutId = match.params.aboutId

    const dispatch = useDispatch()
    const {about} = useSelector(state => state.abouts)

    const [aboutImage, setAboutImage] = useState(null)
    const [aboutHeadOne, setAboutHeadOne] = useState('')
    const [aboutHeadTwo, setAboutHeadTwo] = useState('')
    const [aboutHeadThree, setAboutHeadThree] = useState('')
    const [aboutTextOne, setAboutTextOne] = useState('')
    const [aboutTextTwo, setAboutTextTwo] = useState('')
    const [aboutTextThree, setAboutTextThree] = useState('')


    useEffect(() => {
        if (!about) {
            dispatch(getAbout(aboutId))
        } else {
            setAboutImage(about.about.fileName)
            setAboutTextOne(about.about.aboutTextOne)
            setAboutTextTwo(about.about.aboutTextTwo)
            setAboutTextThree(about.about.aboutTextThree)
            setAboutHeadOne(about.about.aboutHeadOne)
            setAboutHeadTwo(about.about.aboutHeadTwo)
            setAboutHeadThree(about.about.aboutHeadThree)
        }
    }, [dispatch, aboutId, about])

    const handleImageUpload = evt => {
        const image = evt.target.files[0]
        setAboutImage(image)
    }

    const handleAboutSubmit = async evt => {
        evt.preventDefault()

        const formData = new FormData()
        formData.append('aboutImage', aboutImage)
        formData.append('aboutTextOne', aboutTextOne)
        formData.append('aboutTextTwo', aboutTextTwo)
        formData.append('aboutTextThree', aboutTextThree)
        formData.append('aboutHeadOne', aboutHeadOne)
        formData.append('aboutHeadTwo', aboutHeadTwo)
        formData.append('aboutHeadThree', aboutHeadThree)

        updateAbout(formData, aboutId)
            .then(res => {
                history.push('/admin/dashboard')
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <Fragment>
            <AdminHeader/>
            <div className='container my-3'>
                <div className='row'>
                    <div className='col-md-8 mx-auto'>
                        <Link to='/admin/dashboard'>
                            <span className='fa fa-arrow-left'>Назад</span>
                        </Link>
                        <div>
                            <br/>
                            <div className='modal-content'>
                                <form onSubmit={handleAboutSubmit}>
                                    <div className='modal-header bg-info text-white'>
                                        <h5 className='modal-title'>
                                            Изменить название и главную картинку
                                        </h5>
                                    </div>
                                    <div className='modal-body my-2'>
                                        <Fragment>
                                            <label className='btn btn-dark me-4'>
                                                Загрузить картинку
                                                <input
                                                    type='file'
                                                    name='aboutImage'
                                                    accept='images/*'
                                                    hidden
                                                    onChange={handleImageUpload}
                                                />
                                            </label>
                                            {aboutImage && aboutImage.name ? (
                                                <span className='badge bg-secondary'>
													{aboutImage.name}
												</span>
                                            ) : aboutImage ? (
                                                <img
                                                    className='img-thumbnail'
                                                    style={{
                                                        width: '120px',
                                                        height: '80px',
                                                    }}
                                                    src={`/assets/images/${aboutImage}`}
                                                    alt='product'
                                                />
                                            ) : null}

                                            <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Заголовок 1
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='aboutHeadOne'
                                                    value={aboutHeadOne}
                                                    onChange={e => setAboutHeadOne(e.target.value)}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Текст 1
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='aboutTextOne'
                                                    value={aboutTextOne}
                                                    onChange={e => setAboutTextOne(e.target.value)}
                                                />
                                            </div>

                                            <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Заголовок 2
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='aboutHeadTwo'
                                                    value={aboutHeadTwo}
                                                    onChange={e => setAboutHeadTwo(e.target.value)}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Текст 2
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='aboutTextTwo'
                                                    value={aboutTextTwo}
                                                    onChange={e => setAboutTextTwo(e.target.value)}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Заголовок 3
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='aboutHeadThree'
                                                    value={aboutHeadThree}
                                                    onChange={e => setAboutHeadThree(e.target.value)}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label className='text-secondary'>
                                                    Текст 3
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='aboutTextThree'
                                                    value={aboutTextThree}
                                                    onChange={e => setAboutTextThree(e.target.value)}
                                                />
                                            </div>
                                        </Fragment>
                                    </div>
                                    <div className='modal-footer'>
                                        <button type='submit' className='btn btn-success text-white'>
                                            Сохранить
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AdminEditAbout;