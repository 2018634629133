import React from "react";

const ContactInformation = ({info}) => {
    return (
        <div className="contact-infom">
            <h4>Дополнительная информация :</h4>
            <p>{info}</p>
        </div>
    )
}

export default ContactInformation