import axios from "axios";

export const sendMail = async (data) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return await axios.post(`/api/mail`, data, config)
}