import {CREATE_HEADER, GET_HEADERS, DELETE_HEADERS, GET_HEADER} from '../constants/headerConstants'

const INITIAL_STATE = {
    headers: []
}

const headerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_HEADER:
            return {
                headers: [...state.headers, action.payload]
            }
        case GET_HEADERS:
            return {
                headers: [...action.payload]
            }
        case GET_HEADER:
            return {
                header: action.payload
            }
        case DELETE_HEADERS:
            return {
                headers: state.headers.filter(h => h._id !== action.payload._id)
            }
        default:
            return state
    }
}

export default headerReducer