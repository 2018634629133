import React, {useState} from "react";
import isEmpty from "validator/es/lib/isEmpty";
import { showErrorMsg, showSuccessMsg } from "../../helpers/massage";
import { showLoading } from "../../helpers/loading";
import { sendMail } from '../../api/mail'

const ContactForm = () => {
    const [mailData, setMailData] = useState({
        fio: '',
        email: '',
        phone: '',
        text: '',
        errorMsg: false,
        successMsg: false,
        loading: false
    })

    const {fio, email, phone, text, errorMsg, loading, successMsg} = mailData

    const handleMailChange = evt => {
        setMailData({
            ...mailData,
            [evt.target.name]: evt.target.value,
            errorMsg: ''
        })
    }

    const handleMailSubmit = evt => {
        evt.preventDefault()

        if (isEmpty(fio) || isEmpty(email) || isEmpty(phone) || isEmpty(text)) {
            setMailData({
                ...mailData,
                errorMsg: "Все поля обязательные"
            })
        } else {
            const data = {
                fio,
                email,
                phone,
                text
            }
            setMailData({
                ...mailData,
                loading: true
            })
            sendMail(data)
                .then((response) => {
                    setMailData({
                        ...mailData,
                        fio: '',
                        email: '',
                        phone: '',
                        text: '',
                        loading: false,
                        successMsg: response.data.successMessage,
                    })
                })
                .catch(err => {
                    setMailData({
                        ...mailData,
                        loading: false,
                        errorMsg: err.response.data.errorMessage,
                    });
                })
            // setMailData({
            //     ...mailData,
            //     fio: '',
            //     email: '',
            //     phone: '',
            //     text: '',
            // })
        }
    }
    return (
        <div className="contact-form">
            <h4>Обратная связь</h4>
            {errorMsg && showErrorMsg(errorMsg)}
            {successMsg && showSuccessMsg(successMsg)}
            {loading && showLoading()}
            <form onSubmit={handleMailSubmit}>
                <input type="text" name={'fio'} value={fio} onChange={handleMailChange} placeholder="ФИО" required/>
                <input type="email" name={'email'} value={email} onChange={handleMailChange} placeholder="Email" required/>
                <input type="phone" name={'phone'} value={phone} onChange={handleMailChange} placeholder="Телефон" required/>
                <textarea name={'text'} value={text} onChange={handleMailChange} placeholder="Сообщение" required/>
                <button type={'submit'} className="btn1 hvr-sweep-to-bottom">Отправить</button>
            </form>
        </div>
    )
}

export default ContactForm